import './Footer.css'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { productsCategory } from '../standardExportData';
// Screen Animation
import { Fade } from 'react-reveal';
// ICONs
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
// import CallIcon from '@mui/icons-material/Call';

function Footer() {
    const navigate = useNavigate();

    const onLogoClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/');
    }

    return (
        <footer className="footer">
            <div className='footer_cont' >
                <Fade left>
                    <section className='footer_col1' >
                        <img src={require('../../img/logo.png')} alt="Narix" className='footer_logo' onClick={onLogoClick} />
                        <h2>
                            Fully Integrated And Dedicated Pharmaceutical Distributor And Retail Based Company. Ensuring efficient logistics and timely delivery for accessibility and patient care.
                        </h2>
                    </section>
                </Fade>

                <Fade>
                    <section className='footer_col2' >
                        <h4 className='footer_colHeading' >Products</h4>
                        {productsCategory?.map((p, i) => <p key={i} onClick={() => navigate(p.link)} >{p.name}</p>)}
                    </section>
                </Fade>

                {/* <Fade right>
                    <section className='footer_col3' >
                        <h4 className='footer_colHeading' >Services</h4>
                        <p>Name Patient Supply</p>
                        <p>Validated Cold Chain Supply</p>
                        <p>Comparator Sourcing</p>
                    </section>
                </Fade> */}

                <Fade right>
                    <section className='footer_col4'>
                        <h4 className='footer_colHeading' >Contact Us</h4>
                        <a href='https://www.google.com/maps/place/Mohatta+Cloth+Market/@18.9475132,72.8333506,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7ce269482d66f:0x677fcbac46f28f9a!8m2!3d18.9475132!4d72.8355393!16s%2Fg%2F1tg7_yr8' target='_blank' >
                            <LocationOnIcon />
                            Floor No. 1, Shop No.13, 12/14, Mohata Market,
                            Musafirkhana Rd, Crawford Market, Fort,
                            Mumbai, Mumbai, Maharashtra, 400001
                        </a>
                        {/* <a href='mailto:info@narixpharmaceuticals.com' target='_blank' >
                            <MailIcon />
                            info@narixpharmaceuticals.com
                        </a> */}
                        <a href='mailto:narixpharmaceuticals@gmail.com' target='_blank' >
                            <MailIcon />
                            narixpharmaceuticals@gmail.com
                        </a>
                        {/* <a>
                            <CallIcon />
                            +91-22-24311199
                        </a> */}
                        <a href='tel:+919082399514' target='_blank' >
                            <PhoneIphoneIcon />
                            +91-90823 99514
                        </a>
                    </section>
                </Fade>
            </div>
        </footer>
    )
}

export default Footer
