import './App.css';
import { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom"
import { Fab } from '@mui/material';
// Pages
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Products from './pages/Products/Products';
import ContactUs from './pages/ContactUs/ContactUs';
// Components
import CustomNavbar from './components/CustomNavbar/CustomNavbar';
import Footer from './components/Footer/Footer';
// ICONs & SVGs
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: 'smooth'
    });
  }, [pathname]);


  return (
    <div className="app">
      <CustomNavbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/products/:type" element={<Products />} />
      </Routes>

      <Footer />

      <Fab color="primary" href='https://wa.me/919082399514?text=Enquiry%20for%20Pharmaceuticals%20Products' target='_blank' className='app_fabIcon'>
        <WhatsAppIcon sx={{ fontSize: '35px' }} />
      </Fab>
    </div>
  );
}

export default App;
