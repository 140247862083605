import './AboutUs.css';
import React from 'react'
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet';
// Screen Animation
import { Fade, LightSpeed } from 'react-reveal';

function AboutUs() {
    return (
        <div id='aboutUs'>
            <Helmet>
                <title>Narix Pharmaceuticals | About Us</title>
            </Helmet>

            <Fade top>
                <h1 className="aboutUs_header">
                    <span>About Company Overview</span>
                    Welcome to NARIX PHARMACEUTICALS PVT LTD
                </h1>
            </Fade>

            <section className='aboutUs_dataCont' >
                <Fade left>
                    <img src={require('../../img/aboutUs/medImg.jpg')} alt="Narix" />
                </Fade>

                <Fade right>
                    <div className='aboutUs_dataCont_content' >
                        <p>
                            The main goal of Narix is to bring pharmaceutical products to our consumers and ensure their effectiveness, safety, value and consistency. This commitment to quality is deeply rooted in our corporate values ​​and is essential to our further development and success. We strive to comply with the full set of guidelines known as the Narix's Way” when manufacturing and distributing our wide range of medicines.
                        </p>

                        <p>
                            Our company promises to provide the best services to its clients by bridging the gap between demand and supply. Our local presence and global approach have made use of a trusted name amongst the pharma companies who are willing to expand their business and are looking for a platform where they give easy access to the market outside. With unparalleled expertise and knowledge about the international market, NARIX PHARMACEUTICALS PVT LTD has been able to provide unfettered support to its clients. Some of the eminent names that have availed our services include Pfizer, Novartis, Wyeth, Abbott,  Cipla etc.
                        </p>

                        <p>
                            Years of research developments and investment into creating the best infrastructure has not only allowed us to create a name of ourselves in the domestic market but also earned us the admiration and respect of all our International partners too.
                        </p>
                    </div>
                </Fade>
            </section>

            <section className='aboutUs_stripData' >
                <div className="aboutUs_stripData_cont">
                    <div>
                        <p><CountUp end={23} enableScrollSpy={true} /></p>
                        <p>YEARS EXPERIENCE</p>
                    </div>

                    <div>
                        <p><CountUp end={77} enableScrollSpy={true} /></p>
                        <p>COUNTRIES</p>
                    </div>

                    <div>
                        <p><CountUp end={19} enableScrollSpy={true} /></p>
                        <p>COMPANIES</p>
                    </div>

                    <div>
                        <p><CountUp end={735} enableScrollSpy={true} />+</p>
                        <p>PRODUCTS</p>
                    </div>
                </div>
            </section>

            <section className='aboutUs_missionVision'>
                <LightSpeed left>
                    <div className="aboutUs_mission">
                        <img src={require('../../img/aboutUs/mission.png')} alt="Narix Mission" />
                        <h2>Our Mission</h2>
                        <p>
                            Building upon our Group's inspiring motto, "Your Vision, Narix's Mission," our primary objective is to ensure accessibility to top-tier medications at affordable prices for individuals in need. We are steadfastly committed to establishing an invigorating work environment that fosters collaboration and empowers our workforce to strive for excellence in the realm of specialty pharmaceuticals.
                        </p>
                    </div>
                </LightSpeed>

                <LightSpeed right>
                    <div className="aboutUs_vission">
                        <img src={require('../../img/aboutUs/vision.png')} alt="Narix Vision" />
                        <h2>Our Vision</h2>
                        <p>
                            Our aspiration is to emerge as a prominent frontrunner within the Indian pharmaceutical sector, distinguished by our unwavering commitment to ethical business conduct, performance driven by uncompromising quality, and unwavering dedication to fulfilling our customers' requirements. We steadfastly endeavor to uphold the fundamental principles and values upon which our company was established, while simultaneously equipping ourselves to effectively address the evolving demands of the future, with due reverence for time-honored systems.
                        </p>
                    </div>
                </LightSpeed>
            </section>

            <section className='aboutUs_principalValues'>
                <div className="aboutUs_principalValues_cont">
                    <h2>Company Principles & Values</h2>
                    <div className="aboutUs_principalValues_dataCont">
                        {companyPrincipleValues?.map((c, i) => (
                            <Fade bottom key={i}>
                                <div key={i}>
                                    <h6>{c.heading}</h6>
                                    <p>{c.content}</p>
                                </div>
                            </Fade>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AboutUs

const companyPrincipleValues = [
    {
        heading: 'Discipline',
        content: 'We place a strong emphasis on discipline, both within our work environment and in our day-to-day business activities. Our commitment to punctuality is ingrained in our organizational culture, as we consistently strive to deliver results ahead of schedule.'
    },
    {
        heading: 'Trust and Collaboration',
        content: 'Trust serves as a fundamental principle at Narix, playing a pivotal role in fostering enduring client-customer relationships. We firmly believe that establishing and nurturing trust is essential for long-term partnerships, as it forms the bedrock of our interactions and collaborations with our valued clientele'
    },
    {
        heading: 'Transparency and Accountability',
        content: "At Narix, we operate with unwavering ethical standards, emphasizing transparency in our interactions with clients and customers. We prioritize seamless communication to foster trust and openness. Our commitment extends to ensuring the smooth delivery of orders, as we strive to fulfill our promises to our valued customers."
    },
    {
        heading: 'Professionalism',
        content: 'At Narix, we pride ourselves on our professional business approach, which is highly valued by our diverse clientele. We firmly believe that professionalism is essential for long-lasting business growth. It forms the foundation of our organization, enabling us to build strong and trusted relationships with our clients.'
    },
    {
        heading: 'Efficiency, Mindfulness and Innovation',
        content: 'Our work environment values quality over quantity and efficiency over hours. We continuously integrate technology and drive innovation in essential drug manufacturing to enhance our services.'
    },
    {
        heading: 'Customer - oriented',
        content: 'Our company is fully committed to serving user needs, exemplified by our track record of delivering 100% quality products and exceptional customer service in the pharmaceutical industry.'
    },
    {
        heading: 'Ideas over Hierarchy',
        content: 'We foster a culture that values ideas from all team members, disregarding hierarchical boundaries. We understand that a single, impactful idea has the potential to transform our business and drive revenue growth. By promoting open collaboration and idea sharing, we create an environment where every team member is encouraged to contribute their insights and innovative suggestions.'
    },
]