import './CustomNavbar.css';
import React, { Fragment, useState } from 'react'
import { menuItems, productsCategory } from '../standardExportData';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
// ICONs
import MenuIcon from '@mui/icons-material/Menu';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CustomNavbar() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [clicked, setClicked] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const onLinkClick = (link) => {
        if (!link) return;
        navigate(link);
        setClicked(false);
        setAnchorEl(null);
    }
    const handleDropDownClick = (e) => setAnchorEl(e.currentTarget);

    window.addEventListener('scroll', () => {
        var navbar = document.getElementById("navbar");

        navbar.classList.toggle('sticky', window.pageYOffset > 0)
    });
    // window.onscroll = function () {
    //     var navbar = document.getElementById("navbar");

    //     navbar.classList.toggle('sticky', window.pageYOffset > 0)
    // };

    const onLogoClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate('/');
    }


    return (
        <div id='navbar'>
            <section>
                <img src={require('../../img/logo.png')} alt="Narix" className='navbar_logo' onClick={onLogoClick} />

                <div className={clicked ? 'navlist_active' : 'navlist'}>
                    <HighlightOffIcon className='navbar_mobile_menu navbar_mobile_menu_closeIcon' onClick={() => setClicked(false)} />

                    {menuItems.map((item, i) => (
                        <Fragment key={i} >
                            {item?.dropIcon ? (
                                <>
                                    <p onClick={handleDropDownClick} className={`menu_items ${pathname?.split('/')?.includes('products') ? 'menu_items_active' : ''}`}>
                                        {item.name} <ExpandMoreIcon />
                                    </p >
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} >
                                        {productsCategory?.map((p, i) => (
                                            <MenuItem onClick={() => onLinkClick(p?.link)} key={i} className='menu_items_submenu' >
                                                {p?.name}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            ) : (
                                <p onClick={() => onLinkClick(item?.link)} className={`menu_items ${pathname !== '/' && pathname == item?.link ? 'menu_items_active' : ''}`}>
                                    {item.name}
                                </p >
                            )}
                        </Fragment>
                    ))}
                </div>

                {/* < i onClick={handleClick} className={clicked ? 'fas fa-times' : 'fas fa-bars'} > </i> */}
                <MenuIcon className='navbar_mobile_menu' onClick={() => setClicked(true)} />
            </section >
        </div >
    )
}

export default CustomNavbar
