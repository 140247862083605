import './ContactUs.css';
import React, { useState } from 'react';
import { Button, Fab, TextField, Tooltip as MuiTooltip } from '@mui/material';
import { Fade } from 'react-reveal';
import { Helmet } from 'react-helmet';
// MAP
import { MapContainer, TileLayer, Marker, Popup, Tooltip, CircleMarker } from "react-leaflet";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
// ICONs
import NearMeIcon from '@mui/icons-material/NearMe';

function ContactUs() {
    const [contactForm, setContactForm] = useState({ name: '', email: '', phone: '', message: '' })

    const handleChange = (e) => setContactForm({ ...contactForm, [e.target.name]: e.target.value });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(contactForm);
    }

    const svgIcon = new L.Icon({
        iconUrl: require('../../img/marker.png'),
        iconRetinaUrl: require('../../img/marker.png'),
        popupAnchor: [-0, -0],
        iconSize: [30, 30],
        iconAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        className: 'contact_leafletMarkerIcon'
    });

    return (
        <div id='contact'>
            <Helmet>
                <title>Narix Pharmaceuticals | Contact Us</title>
            </Helmet>

            <div className="contact_gmapsCont" >
                <MapContainer
                    style={{ height: "100%", zIndex: 1, outline: 'none' }}
                    center={[18.9475132, 72.8355393]}
                    zoom={15}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                    />

                    <Marker position={[18.9475132, 72.8355393]} icon={svgIcon} eventHandlers={{ click: () => window.open('https://www.google.com/maps/place/Mohatta+Cloth+Market/@18.9475132,72.8333506,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7ce269482d66f:0x677fcbac46f28f9a!8m2!3d18.9475132!4d72.8355393!16s%2Fg%2F1tg7_yr8', '_blank') }}>
                        <Popup>NARIX PHARMACEUTICALS PVT LTD</Popup>
                        <Tooltip>NARIX PHARMACEUTICALS PVT LTD</Tooltip>
                    </Marker>

                    <CircleMarker center={[18.9475132, 72.8355393]} pathOptions={{ color: '#003969' }} radius={30}
                        eventHandlers={{ click: () => window.open('https://www.google.com/maps/place/Mohatta+Cloth+Market/@18.9475132,72.8333506,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7ce269482d66f:0x677fcbac46f28f9a!8m2!3d18.9475132!4d72.8355393!16s%2Fg%2F1tg7_yr8', '_blank') }}
                    />
                </MapContainer>

                <MuiTooltip title='Directions to NARIX PHARMACEUTICALS PVT LTD' className='contact_gmapsCont_locateMe' onClick={() => window.open('https://www.google.com/maps/place/Mohatta+Cloth+Market/@18.9475132,72.8333506,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7ce269482d66f:0x677fcbac46f28f9a!8m2!3d18.9475132!4d72.8355393!16s%2Fg%2F1tg7_yr8', '_blank')} >
                    <Fab size="small" aria-label="NARIX PHARMACEUTICALS PVT LTD" sx={{ backgroundColor: '#003969 !important' }} >
                        <NearMeIcon sx={{ color: '#fcfc01' }} />
                    </Fab>
                </MuiTooltip>
            </div>

            <section className='contact_gitws' >
                <Fade left>
                    <div className="contact_gitws_sec1">
                        <h1 className="contact_gitws_title" >Get In Touch With Us</h1>
                        <p>
                            For general inquiries or questions, please feel free to send us a message, and our dedicated team will promptly respond to you. Alternatively, you can reach out to us directly by phone to speak with one of our knowledgeable service representatives. We are here to assist you and provide the information you need.
                        </p>
                    </div>
                </Fade>

                <Fade right>
                    <div className="contact_gitws_sec2">
                        <form onSubmit={handleSubmit} >
                            <TextField label="Name" name='name' variant="outlined" onChange={handleChange} fullWidth />
                            <TextField label="Email" type='email' name='email' variant="outlined" onChange={handleChange} fullWidth />
                            <TextField label="Your Phone" type='number' name='phone' variant="outlined" onChange={handleChange} fullWidth />
                            <TextField label="Message" name='message' variant="outlined" onChange={handleChange} fullWidth
                                multiline maxRows={7} minRows={5}
                            />

                            <Button type='submit' variant='contained' >
                                Send Message
                            </Button>
                        </form>
                    </div>
                </Fade>
            </section>
        </div>
    )
}

export default ContactUs