import './Products.css';
import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useParams } from 'react-router-dom';
import { Fade } from 'react-reveal';
import { Helmet } from 'react-helmet';

function Products() {
    const fullScreenDialog = useMediaQuery('(max-width: 500px)');
    const { type } = useParams();

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogueData, setDialogueData] = useState({});

    const [showHoverImage, setShowHoverImage] = useState({ id: null, show: false });

    const onProductClick = (data) => {
        if (type === 'ed') return;

        setOpenDialog(true);
        setDialogueData(data);
    }

    const getproductTypeTitle = () => {
        switch (type) {
            case 'cancer':
                return 'Oncology Medicines'

            case 'cardiac':
                return 'Cardiology Medicines'

            case 'antidiabetic':
                return 'Antidiabetic Medicines'

            case 'ed':
                return 'Erectile Dysfunction Medicines'

            case 'hepatitis':
                return 'Hepatitis Medicines'

            default:
                return ''
        }
    }

    const getproductTypeArr = () => {
        switch (type) {
            case 'cancer':
                return cancerCatlogue

            case 'cardiac':
                return cardiacCatlogue

            case 'antidiabetic':
                return diabeticCatlogue

            case 'ed':
                return edCatlogue


            case 'hepatitis':
                return hepatitisCatlogue

            default:
                return []
        }
    }

    return (
        <div id='products'>
            <Helmet>
                <title>Narix Pharmaceuticals | {getproductTypeTitle()}</title>
            </Helmet>

            <div className="products_cont">
                <h1 className='products_cont_title' >{getproductTypeTitle()}</h1>

                <section className="products_productCont">
                    {getproductTypeArr()?.map((m, i) => (
                        <div className='products_product' key={i} onClick={() => onProductClick(m)}
                            onMouseEnter={() => setShowHoverImage({ id: i, show: true })}
                            onMouseLeave={() => setShowHoverImage({ id: null, show: false })}
                        >
                            <Fade top>
                                {type === 'ed' ? (
                                    <img src={showHoverImage?.show && showHoverImage?.id === i ? m?.dialogImage : m?.displayImage}
                                        alt={m?.brandName} title={m?.brandName} longdesc={m?.description || m?.brandName} style={{ objectFit: m?.imageFit || 'contain' }}
                                    />
                                ) : (
                                    <img src={m?.displayImage} alt={m?.brandName} title={m?.brandName}
                                        longdesc={m?.description || m?.brandName} style={{ objectFit: m?.imageFit || 'contain' }}
                                    />
                                )}

                                <h1>{m.brandName}</h1>
                                {type === 'ed' && m.manufacturer ? <h3>{m.manufacturer}</h3> : <></>}
                                {m.composition ? <h2>{m.composition}</h2> : <></>}
                            </Fade>
                        </div>
                    ))}
                </section>
            </div>

            <Dialog fullScreen={fullScreenDialog} fullWidth maxWidth='md' open={openDialog} onClose={() => setOpenDialog(false)} className='products_productDialog' >
                <DialogTitle className='products_productDialog_title' >
                    <IconButton onClick={() => setOpenDialog(false)} >
                        <ArrowBackIosNewIcon sx={{ color: '#003969' }} />
                    </IconButton>
                    <p>{dialogueData?.brandName}</p>
                </DialogTitle>
                <DialogContent className='products_productDialog_content' >
                    <img src={dialogueData?.dialogImage} alt={dialogueData?.brandName} title={dialogueData?.brandName}
                        longdesc={dialogueData?.description}
                    />

                    <div className='products_productDialog_content_headerWithText' >
                        <h2>Composition: <span>{dialogueData?.composition || dialogueData?.brandName}</span></h2>
                        <h2>Strength: <span>{dialogueData?.strength}</span></h2>
                        <h2>Manufactured By: <span>{dialogueData?.manufacturer}</span></h2>
                    </div>

                    <p>{dialogueData?.description}</p>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default Products

const edCatlogue = [
    {
        displayImage: require('../../img/products/ed/Apcalis.jpg'),
        dialogImage: require('../../img/products/ed/Apcalis1.jpg'),
        brandName: 'Apcalis - SX Oral Jelly',
        manufacturer: 'AJANTA PHARMA',
    },
    {
        displayImage: require('../../img/products/ed/AVANA-100.jpg'),
        dialogImage: require('../../img/products/ed/AVANA-100 1.jpg'),
        brandName: 'Avana 100mg',
        manufacturer: 'SUNRISE REMEDIES',
    },
    {
        displayImage: require('../../img/products/ed/AVANA-50.jpg'),
        dialogImage: require('../../img/products/ed/AVANA-50 1.jpg'),
        brandName: 'Avana 50mg',
        manufacturer: 'SUNRISE REMEDIES',
    },
    {
        displayImage: require('../../img/products/ed/Extra Super Avana.jpg'),
        dialogImage: require('../../img/products/ed/Extra Super Avana 1.jpg'),
        brandName: 'Extra Super Avana',
        manufacturer: 'SUNRISE REMEDIES',
    },
    {
        displayImage: require('../../img/products/ed/Extra Super P Force.jpg'),
        dialogImage: require('../../img/products/ed/Extra Super P Force1.jpg'),
        brandName: 'Extra Super P Force',
        manufacturer: 'Wellona Pharma',
    },
    {
        displayImage: require('../../img/products/ed/EXTRA SUPER TADARISE.jpg'),
        dialogImage: require('../../img/products/ed/EXTRA SUPER TADARISE 2.jpg'),
        brandName: 'Extra Super Tadarise',
        manufacturer: 'Wellona Pharma',
    },
    {
        displayImage: require('../../img/products/ed/EXTRA SUPER ZHEWITRA.jpg'),
        dialogImage: require('../../img/products/ed/EXTRA SUPER ZHEWITRA 2.jpg'),
        brandName: 'Extra Super Zhewitra',
        manufacturer: 'SUNRISE REMEDIES',
    },
    {
        displayImage: require('../../img/products/ed/FEMALEGRA-100.jpg'),
        dialogImage: require('../../img/products/ed/FEMALEGRA-100 1.jpeg'),
        brandName: 'Femalegra 100mg',
    },
    {
        displayImage: require('../../img/products/ed/kamagara chewable.jpg'),
        dialogImage: require('../../img/products/ed/Kamagra  Chewable 2.jpg'),
        brandName: 'Kamagra Soft Chewable',
        manufacturer: 'AJANTA PHARMA',
    },
    {
        displayImage: require('../../img/products/ed/KAMAGRA EFFERVESCENT.jpg'),
        dialogImage: require('../../img/products/ed/KAMAGRA EFFERVESCENT 2.jpg'),
        brandName: 'Kamagra Effervescent',
        manufacturer: 'AJANTA PHARMA',
    },
    {
        displayImage: require('../../img/products/ed/KAMAGRA GOLD 50MG.jpg'),
        dialogImage: require('../../img/products/ed/KAMAGRA GOLD 50MG 1.jpg'),
        brandName: 'Kamagra Gold 50 mg',
        manufacturer: 'AJANTA PHARMA',
    },
    {
        displayImage: require('../../img/products/ed/KAMAGRA GOLD 100MG.jpg'),
        dialogImage: require('../../img/products/ed/KAMAGRA GOLD 100MG 1.jpg'),
        brandName: 'Kamagra Gold 100 mg',
        manufacturer: 'AJANTA PHARMA',
    },
    {
        displayImage: require('../../img/products/ed/KAMAGRA JELLY VOL-I.jpg'),
        dialogImage: require('../../img/products/ed/KAMAGRA JELLY VOL-I [2].jpg'),
        brandName: 'Kamagra Oral Jelly',
        manufacturer: 'AJANTA PHARMA',
    },
    {
        displayImage: require('../../img/products/ed/KAMAGRA POLO (PINEAPPLE+MINT)2.jpg'),
        dialogImage: require('../../img/products/ed/KAMAGRA POLO (PINEAPPLE+MINT) 1.jpg'),
        brandName: 'Kamagra Polo',
        manufacturer: 'AJANTA PHARMA',
    },
    {
        displayImage: require('../../img/products/ed/Kamagra-FX.jpg'),
        dialogImage: require('../../img/products/ed/Kamagra-FX 1.jpg'),
        brandName: 'Kamagra FX',
        manufacturer: 'AJANTA PHARMA',
    },
    {
        displayImage: require('../../img/products/ed/Lovegra-100mg 3.jpeg'),
        dialogImage: require('../../img/products/ed/LOVEGRA 100.jpg'),
        brandName: 'Lovegra Tablet',
        manufacturer: 'AJANTA PHARMA',
    },
]

const cancerCatlogue = [
    {
        displayImage: require('../../img/products/cancer/hertraz-440-mg-trastuzumab-injection-2.png'),
        dialogImage: require('../../img/products/cancer/hertraz-440-mg-trastuzumab-injection.jpg'),
        brandName: 'Hertraz 440 mg Injection ',
        composition: 'Trastuzumab 440 mg',
        manufacturer: 'Mylan Pharmaceuticals Pvt Ltd',
        strength: '150 mg, 440 mg',
        description: `Hertraz 440 mg Injection  is a highly effective medication used in the treatment of certain types of cancer. It contains the active ingredient Trastuzumab, which belongs to a class of medications known as monoclonal antibodies.

        Hertraz 440 mg Injection  is specifically indicated for the treatment of HER2-positive breast cancer, which is a type of breast cancer that overexpresses the human epidermal growth factor receptor 2 (HER2). This medication works by targeting and inhibiting the HER2 protein, thereby slowing down the growth and spread of cancer cells.
        
        The injection is administered directly into a vein (intravenous infusion) under the supervision of a healthcare professional. The dosage and duration of treatment may vary depending on individual factors such as the stage and severity of the cancer. It is essential to follow the prescribed treatment regimen and attend regular medical check-ups to monitor the progress of the therapy.
        
        Hertraz 440 mg Injection  is often used in combination with other chemotherapy drugs or as a standalone treatment. It has demonstrated significant efficacy in reducing the risk of disease recurrence and improving overall survival rates in HER2-positive breast cancer patients.
        
        While Hertraz Injection is generally well-tolerated, it may cause certain side effects. Common side effects include fever, chills, fatigue, nausea, vomiting, diarrhea, headache, muscle or joint pain, and rash. It is important to inform the healthcare provider about any discomfort or adverse reactions experienced during the treatment.
        
        As with any medication, Hertraz 440 mg Injection  may not be suitable for everyone. It is crucial to disclose a comprehensive medical history, including any existing medical conditions, ongoing treatments, or allergies, to the healthcare professional before initiating therapy.
        
        Patients who are pregnant or breastfeeding should discuss the potential risks and benefits of Hertraz Injection with their healthcare provider, as it may not be recommended in these situations.
        `
    },
    {
        displayImage: require('../../img/products/cancer/rituxirel-500mg-injection-50ml.jpg'),
        dialogImage: require('../../img/products/cancer/rituxirel-500mg-injection-50ml.jpg'),
        brandName: 'Rituxirel 500mg Injection',
        composition: 'Rituximab 500mg',
        manufacturer: 'Reliance Life Sciences',
        strength: '100 mg, 500 mg',
        description: `Rituxirel 500mg is a medication used in the treatment of certain types of cancer and autoimmune diseases. It contains the active ingredient Rituximab, which belongs to a class of medications known as monoclonal antibodies.

        Rituxirel 500mg is primarily indicated for the treatment of non-Hodgkin's lymphoma, chronic lymphocytic leukemia, rheumatoid arthritis, granulomatosis with polyangiitis, and microscopic polyangiitis. It works by targeting specific cells in the body, such as B cells or cancer cells, and inhibiting their growth or destroying them.
        
        The medication is administered through intravenous infusion, typically under the supervision of a healthcare professional in a clinical setting. The dosage and treatment duration may vary based on the specific condition being treated and individual patient factors. Regular monitoring and follow-up visits are essential during the treatment period.
        
        Rituxirel 500mg is often used in combination with other chemotherapy drugs or as part of a broader treatment plan. It has shown significant efficacy in reducing the progression of cancer and controlling autoimmune diseases, leading to improved outcomes and quality of life for patients.
        
        Like any medication, Rituxirel 500mg may cause side effects. Common side effects may include infusion-related reactions, fatigue, fever, chills, nausea, vomiting, infections, and low blood cell counts. It is important to report any side effects or adverse reactions to a healthcare professional promptly.
        
        Before starting Rituxirel 500mg, it is crucial to provide a comprehensive medical history, including ongoing treatments, allergies, and current medications, to the healthcare provider. This information helps ensure the safe and effective use of the medication and minimizes the risk of potential drug interactions or complications.
        
        Pregnant or breastfeeding individuals should discuss the potential risks and benefits of Rituxirel 500mg with their healthcare provider, as it may not be recommended in such cases.
        
        Rituxirel 500mg is a prescription-only medication, and its use should be strictly as directed by a qualified healthcare professional.
        `
    },
    {
        displayImage: require('../../img/products/cancer/ZYBIRAA-250MG.jpg'),
        dialogImage: require('../../img/products/cancer/ZYBIRAA-500MG.jpg'),
        brandName: 'Zybiraa 250mg Tablet',
        composition: 'Abiraterone Acetate 250mg',
        manufacturer: 'Zydus Cadila',
        strength: '250 mg, 500 mg',
        description: `Zybiraa tablets are oral medications available in two strengths: 250mg and 500mg. These tablets contain the active ingredient Zybiraa, which is an antiviral drug belonging to the class of nucleoside analogues.

        Zybiraa tablets are primarily prescribed for the treatment of viral infections caused by the herpes simplex virus (HSV), including genital herpes and cold sores. The medication works by inhibiting the replication of the virus, thus reducing the severity and duration of outbreaks.
        
        The dosage of Zybiraa tablets may vary depending on the specific condition being treated, the patient's medical history, and the severity of the infection. It is essential to follow the prescribed dosage and duration of treatment as advised by a healthcare professional.
        
        Zybiraa tablets are generally taken orally with or without food. It is important to drink plenty of fluids while taking the medication to stay hydrated and minimize the risk of side effects.
        
        While Zybiraa tablets are generally well-tolerated, they may cause certain side effects. Common side effects include headache, nausea, diarrhea, abdominal pain, and fatigue. If any side effects persist or worsen, it is recommended to consult a healthcare professional.
        
        It is important to disclose any existing medical conditions, ongoing treatments, or allergies to the healthcare provider before starting Zybiraa tablets. This information helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Pregnant or breastfeeding individuals should discuss the potential risks and benefits of Zybiraa tablets with their healthcare provider, as it may not be recommended in such cases.
        
        Zybiraa tablets are available only by prescription and should be used as directed by a qualified healthcare professional.
        `
    },
    {
        displayImage: require('../../img/products/cancer/afatinib-dimaleate-tablets-40-mg-xovoltib.webp'),
        dialogImage: require('../../img/products/cancer/afatinib-dimaleate-tablets-40-mg-xovoltib.jpeg'),
        brandName: 'Xovoltib 20mg Tablet',
        composition: 'Afatinib dimaleate 40mg',
        manufacturer: 'Boehringer Ingelheim',
        strength: '20 mg, 30 mg, 40 mg, 50 mg',
        description: `Xovoltib is a range of medications used in the treatment of certain types of cancer. These medications contain the active ingredient Afatinib, which belongs to a class of drugs known as tyrosine kinase inhibitors.

        The Xovoltib range includes various strengths, such as 20mg, 30mg, 40mg and 50mg tablets. These tablets are specifically indicated for the treatment of non-small cell lung cancer (NSCLC) that has specific mutations in the epidermal growth factor receptor (EGFR) gene.
        
        Xovoltib works by targeting and inhibiting the abnormal EGFR protein, which plays a key role in the growth and spread of cancer cells. By blocking this protein, Xovoltib helps to slow down the progression of the disease and improve overall survival rates in patients with NSCLC.
        
        The dosage of Xovoltib may vary depending on the specific condition being treated, individual patient factors, and the healthcare professional's recommendation. It is crucial to follow the prescribed dosage and treatment duration as instructed by a qualified healthcare provider.
        
        Xovoltib tablets are typically taken orally, usually with a meal. It is important to swallow the tablets whole and not crush or chew them. If a dose is missed, it is generally advised to take the next scheduled dose and not double the dose to make up for the missed one.
        
        Common side effects of Xovoltib may include diarrhea, skin rash, dry skin, nail changes, fatigue, nausea, and loss of appetite. It is important to report any severe or persistent side effects to a healthcare professional.
        
        Before starting Xovoltib, it is essential to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Pregnant or breastfeeding individuals should discuss the potential risks and benefits of Xovoltib with their healthcare provider, as it may not be recommended in such cases.
        
        Xovoltib is a prescription-only medication and should be used under the supervision of a qualified healthcare professional.`
    },
    {
        displayImage: require('../../img/products/cancer/bortenat-injection.jpg'),
        dialogImage: require('../../img/products/cancer/bortenat-injection-3.5mg.jpg'),
        brandName: 'Bortenat 3.5mg Injection',
        composition: 'Bortezomib 3.5 mg',
        manufacturer: 'Natco Pharma Ltd',
        strength: '2 mg, 3.5 mg',
        description: `Bortenat Injection is a potent medication used in the treatment of multiple myeloma, a type of cancer that affects plasma cells in the bone marrow. It contains the active ingredient Bortezomib, which belongs to a class of drugs known as proteasome inhibitors.

        Bortenat Injection is specifically indicated for patients who have received at least one prior therapy. It works by blocking the action of proteasomes, cellular complexes that play a role in the breakdown of proteins. By inhibiting proteasomes, Bortenat slows down the growth of cancer cells and promotes their death.
        
        The injection is administered intravenously by a healthcare professional in a clinical setting. The dosage and treatment schedule may vary depending on individual factors such as the patient's overall health, response to treatment, and other ongoing therapies.
        
        Bortenat Injection is often used as part of a combination therapy with other medications to maximize its effectiveness. It has shown significant efficacy in improving overall survival and delaying disease progression in patients with multiple myeloma.
        
        During the treatment with Bortenat Injection, regular monitoring of blood counts and kidney function is essential to ensure the safety and effectiveness of the medication. It is important to follow all instructions given by the healthcare provider and attend scheduled medical check-ups.
        
        Common side effects of Bortenat Injection may include fatigue, nausea, vomiting, diarrhea, constipation, peripheral neuropathy, decreased appetite, and low blood cell counts. It is important to promptly report any severe or persistent side effects to a healthcare professional.
        
        Prior to starting Bortenat Injection, it is crucial to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps to ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Bortenat Injection is available only by prescription and should be used as directed by a qualified healthcare professional.
        `
    },
    {
        displayImage: require('../../img/products/cancer/Zaxol-100mg.png'),
        dialogImage: require('../../img/products/cancer/Zaxol-100.png'),
        brandName: 'Zaxol-100',
        composition: 'Paclitaxel 100 mg',
        manufacturer: 'Zuvius Life Sciences',
        strength: '30 mg, 100 mg, 260 mg, 300 mg',
        description: `Zaxol-100 is a medication that contains the active ingredient Zaxofloxacin, which belongs to a class of drugs known as fluoroquinolone antibiotics. It is primarily used to treat bacterial infections in various parts of the body.

        Zaxol-100 is indicated for the treatment of respiratory tract infections, urinary tract infections, skin and soft tissue infections, and certain sexually transmitted infections. It works by inhibiting the growth and multiplication of bacteria, thereby helping to eliminate the infection.
        
        The dosage and duration of treatment with Zaxol-100 may vary depending on the type and severity of the infection, as well as the patient's age, weight, and overall health. It is important to follow the prescribed dosage and complete the full course of treatment as directed by a healthcare professional.
        
        Zaxol-100 is typically taken orally with or without food. It is important to take the medication at regular intervals and not skip any doses. If a dose is missed, it should be taken as soon as remembered unless it is close to the time for the next scheduled dose.
        
        Common side effects of Zaxol-100 may include nausea, vomiting, diarrhea, headache, dizziness, and changes in taste. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Zaxol-100, it is important to inform the healthcare provider about any existing medical conditions, ongoing treatments, allergies, or history of sensitivity to antibiotics. This information helps to ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Zaxol-100 is a prescription-only medication and should be used under the supervision of a qualified healthcare professional. It is important to complete the full course of treatment, even if symptoms improve, to ensure that the infection is fully eradicated.`
    },
    {
        displayImage: require('../../img/products/cancer/Oxpla-Oxaliplatin-100mg-Injection.avif'),
        dialogImage: require('../../img/products/cancer/Oxpla-Oxaliplatin-50mg-Injection.png'),
        brandName: 'Oxpla 100mg Injection',
        composition: 'Oxaliplatin 50mg/100mg',
        manufacturer: 'Zydus Cadila',
        strength: '50 mg, 100 mg',
        description: `Oxpla Injection is a medication that contains the active ingredient Oxaliplatin, which belongs to a class of drugs known as platinum-based antineoplastic agents. It is primarily used in the treatment of colorectal cancer.

        Oxpla Injection is administered intravenously by a healthcare professional in a clinical setting. It is typically used in combination with other chemotherapy medications to maximize its effectiveness. The dosage and treatment schedule may vary depending on the specific cancer stage, patient's overall health, and the healthcare provider's recommendation.
        
        Oxpla Injection works by interfering with the DNA replication process in cancer cells, leading to their destruction. It helps to inhibit the growth and spread of cancer cells and improve the chances of treatment success.
        
        During treatment with Oxpla Injection, regular monitoring of blood counts and kidney function is essential to ensure the safe and effective use of the medication. It is important to follow all instructions provided by the healthcare provider and attend scheduled medical check-ups.
        
        Common side effects of Oxpla Injection may include nausea, vomiting, diarrhea, peripheral neuropathy (numbness or tingling in the hands or feet), fatigue, and changes in blood cell counts. It is important to promptly report any severe or persistent side effects to a healthcare professional.
        
        Before starting Oxpla Injection, it is crucial to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Oxpla Injection is available only by prescription and should be used as directed by a qualified healthcare professional. It is important to complete the full course of treatment unless otherwise instructed by the healthcare provider.`
    },
    {
        displayImage: require('../../img/products/cancer/xtandi-40mg.jpg'),
        dialogImage: require('../../img/products/cancer/xtandi-40mg.png'),
        brandName: 'Xtandi 40mg Soft Capsule',
        composition: 'Enzalutamide 40 mg',
        manufacturer: 'Astellas Pharma Inc',
        strength: '40 mg',
        description: `Xtandi 40mg is a medication prescribed for the treatment of prostate cancer that has spread to other parts of the body. It contains the active ingredient Enzalutamide, which belongs to a class of drugs known as androgen receptor inhibitors.

        Xtandi 40mg is specifically indicated for patients with metastatic castration-resistant prostate cancer (mCRPC) who have previously received docetaxel chemotherapy. It works by blocking the action of androgens (male hormones) in the body, which helps to slow down the growth and spread of prostate cancer cells.
        
        The recommended dosage of Xtandi is 160mg (four capsules of 40mg) taken orally once daily. The capsules should be swallowed whole and can be taken with or without food. It is important to follow the prescribed dosage and treatment schedule as advised by a healthcare professional.
        
        Xtandi 40mg is usually taken along with a gonadotropin-releasing hormone (GnRH) analog or as part of combination therapy with other medications. It has shown significant efficacy in improving overall survival and delaying disease progression in patients with mCRPC.
        
        During treatment with Xtandi 40mg, regular monitoring of liver function and blood pressure is important to ensure the safe and effective use of the medication. It is crucial to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Xtandi 40mg may include fatigue, hot flashes, back pain, joint pain, diarrhea, headache, and high blood pressure. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Xtandi 40mg, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Xtandi 40mg is a prescription-only medication and should be used under the supervision of a qualified healthcare professional.`
    },
    {
        displayImage: require('../../img/products/cancer/jevtana-injection-60mg.png'),
        dialogImage: require('../../img/products/cancer/jevtana-injection-60mg.jpg'),
        brandName: 'Jevtana Injection',
        composition: 'Cabazitaxel 60mg/ml',
        manufacturer: 'Emcure Pharmaceuticals Ltd',
        strength: '60 mg',
        description: `Jevtana Injection is a medication used in the treatment of metastatic castration-resistant prostate cancer (mCRPC). It contains the active ingredient Cabazitaxel, which belongs to a class of drugs known as taxane-based antineoplastic agents.

        Jevtana Injection is indicated for patients who have previously received docetaxel chemotherapy. It works by interfering with the microtubules in cancer cells, leading to cell death and slowing down the progression of prostate cancer.
        
        The injection is administered intravenously by a healthcare professional in a clinical setting. The dosage and treatment schedule may vary depending on individual factors such as the patient's overall health, response to treatment, and other ongoing therapies.
        
        Jevtana Injection is typically used in combination with prednisone, a corticosteroid medication, to minimize potential side effects. It has shown significant efficacy in improving overall survival and progression-free survival in patients with mCRPC.
        
        During treatment with Jevtana Injection, regular monitoring of blood counts and liver function is essential to ensure the safe and effective use of the medication. It is important to follow all instructions given by the healthcare provider and attend scheduled medical check-ups.
        
        Common side effects of Jevtana Injection may include fatigue, nausea, diarrhea, constipation, hair loss, peripheral neuropathy (numbness or tingling in the hands or feet), and low blood cell counts. It is important to promptly report any severe or persistent side effects to a healthcare professional.
        
        Before starting Jevtana Injection, it is crucial to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps to ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Jevtana Injection is available only by prescription and should be used as directed by a qualified healthcare professional.`
    },
    {
        displayImage: require('../../img/products/cancer/sorafenib-tosylate-tablets-nexavar 0.2mg.jpg'),
        dialogImage: require('../../img/products/cancer/sorafenib-tosylate-tablets-nexavar 0.png'),
        brandName: 'Nexavar 0.2g',
        composition: 'Sorafenib Tosylate 200gm',
        manufacturer: 'Zaydus Cadila',
        strength: '200 mg',
        description: `Nexavar 0.2g is a medication that contains the active ingredient Sorafenib, which belongs to a class of drugs known as multikinase inhibitors. It is primarily used in the treatment of certain types of cancers, including liver cancer, kidney cancer, and thyroid cancer.

        Nexavar 0.2g works by inhibiting the activity of specific proteins involved in cancer cell growth and blood vessel formation. This helps to slow down the progression of cancer and reduce the blood supply to tumor cells.
        
        The dosage and treatment duration of Nexavar 0.2g may vary depending on the type and stage of cancer, as well as individual patient factors. It is important to follow the prescribed dosage and treatment schedule as directed by a healthcare professional.
        
        Nexavar 0.2g is typically taken orally, usually with a meal. The tablets should be swallowed whole and not crushed or chewed. It is important to take the medication at the same time each day to maintain a consistent level in the body.
        
        During treatment with Nexavar 0.2g, regular monitoring of liver function, blood pressure, and thyroid function is essential to ensure the safe and effective use of the medication. It is crucial to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Nexavar 0.2g may include fatigue, diarrhea, hand-foot skin reaction (redness, swelling, or pain on the palms of the hands and soles of the feet), rash, hair loss, nausea, and loss of appetite. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Nexavar 0.2g, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Nexavar 0.2g is a prescription-only medication and should be used under the supervision of a qualified healthcare professional.`
    },
    {
        displayImage: require('../../img/products/cancer/erlotinib-tablets-100.jpg'),
        dialogImage: require('../../img/products/cancer/erlocip_100mg.jpg'),
        brandName: 'Erlocip 100 Tablet',
        composition: 'Erlotinib 100 mg',
        manufacturer: 'Cipla Ltd',
        strength: '100 mg, 150 mg',
        description: `Erlocip 100 Tablet is a medication that contains the active ingredient Erlotinib hydrochloride, which belongs to a class of drugs known as tyrosine kinase inhibitors. It is primarily used in the treatment of non-small cell lung cancer (NSCLC) and pancreatic cancer.

        Erlocip 100 Tablet works by inhibiting the activity of epidermal growth factor receptor (EGFR), a protein that promotes the growth and division of cancer cells. By blocking this protein, it helps to slow down the progression of cancer and improve overall survival rates.
        
        The dosage and treatment duration of Erlocip 100 Tablet may vary depending on the specific type and stage of cancer, as well as individual patient factors. It is important to follow the prescribed dosage and treatment schedule as directed by a healthcare professional.
        
        Erlocip 100 Tablet is typically taken orally, usually on an empty stomach at least one hour before or two hours after a meal. The tablet should be swallowed whole with a glass of water. It is important not to crush or chew the tablet unless advised by a healthcare professional.
        
        During treatment with Erlocip 100 Tablet, regular monitoring of liver function and blood counts is essential to ensure the safe and effective use of the medication. It is crucial to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Erlocip 100 Tablet may include rash, diarrhea, nausea, vomiting, fatigue, loss of appetite, and skin dryness. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Erlocip 100 Tablet, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Erlocip 100 Tablet is a prescription-only medication and should be used under the supervision of a qualified healthcare professional.`
    },
    {
        displayImage: require('../../img/products/cancer/Petaxel Injection.png'),
        dialogImage: require('../../img/products/cancer/paclitaxel-injection.png'),
        brandName: 'Petaxel 260mg Injection',
        composition: 'Paclitaxel 260 mg',
        manufacturer: 'Biochem Pharmaceutical Industries',
        strength: '30 mg, 100 mg, 260 mg',
        description: `Petaxel 260mg Injection is a medication that contains the active ingredient Paclitaxel, which belongs to a class of drugs known as taxanes. It is primarily used in the treatment of various types of cancer, including breast cancer, lung cancer, ovarian cancer, and pancreatic cancer.

        Petaxel 260mg Injection works by inhibiting the growth and division of cancer cells, thereby slowing down tumor growth and preventing their spread to other parts of the body. It interferes with microtubule structures within the cancer cells, disrupting their normal function and leading to cell death.
        
        The injection is administered intravenously by a healthcare professional in a clinical setting. The dosage and treatment duration may vary depending on the type and stage of cancer, as well as individual patient factors. It is important to follow the prescribed dosage and treatment schedule as directed by a healthcare professional.
        
        Petaxel 260mg Injection is often used in combination with other chemotherapy medications or as part of a multidrug regimen. The specific treatment plan will be determined by the healthcare provider based on the patient's condition and treatment goals.
        
        During treatment with Petaxel 260mg Injection, regular monitoring of blood counts, liver function, and kidney function is essential to ensure the safe and effective use of the medication. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Petaxel 260mg Injection may include nausea, vomiting, hair loss, fatigue, changes in blood cell counts, peripheral neuropathy (numbness or tingling in the hands or feet), and allergic reactions. It is important to promptly report any severe or persistent side effects to a healthcare professional.
        
        Before starting Petaxel 260mg Injection, it is crucial to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Petaxel 260mg Injection is a prescription-only medication and should be used under the supervision of a qualified healthcare professional.`
    }
]

const cardiacCatlogue = [
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/Nicorandil Tablets IP 10 mg.png'),
        dialogImage: require('../../img/products/cardiac/Nicorandil Tablets IP 10 mg.png'),
        brandName: 'Nicorandil 10mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '5 mg, 10 mg, 20 mg',
        description: `Nicorandil Tablets are a medication commonly prescribed for the treatment of angina pectoris, a condition characterized by chest pain or discomfort caused by reduced blood flow to the heart muscle.

        Nicorandil works by dual mechanisms. Firstly, it acts as a potassium channel opener, helping to relax and widen the blood vessels, which improves blood flow to the heart. Secondly, it has a direct effect on the heart, reducing its workload and increasing the oxygen supply.
        
        The dosage of Nicorandil Tablets may vary depending on the severity of angina and individual patient response. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        These tablets are taken orally with or without food, usually once or twice a day as directed by a healthcare professional. They should be swallowed whole with a glass of water and not crushed or chewed.
        
        Regular monitoring of blood pressure, heart rate, and overall cardiovascular health is essential during treatment with Nicorandil Tablets to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Nicorandil Tablets may include headaches, dizziness, flushing, and gastrointestinal disturbances such as nausea and vomiting. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Nicorandil Tablets, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Nicorandil Tablets are a prescription medication and should be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/carvedilol 25mg.png'),
        dialogImage: require('../../img/products/cardiac/carvedilol 25mg.png'),
        brandName: 'Carvedilol 25mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '3.125 mg, 6.25 mg, 10 mg, 12.5 mg, 20 mg, 25 mg, 40 mg ',
        description: `Carvedilol Tablets are a medication that belongs to a class of drugs known as beta-blockers. They are primarily prescribed for the treatment of high blood pressure (hypertension), heart failure, and certain heart rhythm disorders.

        Carvedilol works by blocking the action of certain chemicals in the body, such as adrenaline, that can increase heart rate and blood pressure. By blocking these effects, Carvedilol helps to relax blood vessels, reduce the workload on the heart, and improve heart function.
        
        The dosage of Carvedilol Tablets may vary depending on the condition being treated and individual patient response. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Carvedilol Tablets are usually taken orally, with or without food, one to two times a day as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water and not crushed or chewed.
        
        Regular monitoring of blood pressure, heart rate, and overall cardiovascular health is essential during treatment with Carvedilol Tablets to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Carvedilol Tablets may include dizziness, fatigue, low blood pressure, slow heart rate, and gastrointestinal disturbances such as nausea and diarrhea. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Carvedilol Tablets, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Carvedilol Tablets are a prescription-only medication and should be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/enalapril maleate 2.5mg.png'),
        dialogImage: require('../../img/products/cardiac/enalapril maleate 2.5mg.png'),
        brandName: 'Enalapril 2.5mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '2.5 mg, 5 mg,  10 mg',
        description: `Enalapril Tablets are a medication belonging to a class of drugs known as angiotensin-converting enzyme (ACE) inhibitors. They are primarily prescribed for the treatment of high blood pressure (hypertension), heart failure, and certain kidney conditions.

        Enalapril works by inhibiting the activity of the angiotensin-converting enzyme, which is responsible for the production of a hormone called angiotensin II. By blocking the effects of angiotensin II, Enalapril helps to relax and widen blood vessels, reduce fluid retention, and lower blood pressure.
        
        The dosage of Enalapril Tablets may vary depending on the condition being treated and individual patient response. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Enalapril Tablets are usually taken orally, with or without food, once or twice a day as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of blood pressure, kidney function, and electrolyte levels is essential during treatment with Enalapril Tablets to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Enalapril Tablets may include dizziness, fatigue, cough, headache, and gastrointestinal disturbances such as nausea and diarrhea. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Enalapril Tablets, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Enalapril Tablets are a prescription medication and should be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/ramipril 5mg.png'),
        dialogImage: require('../../img/products/cardiac/ramipril 5mg.png'),
        brandName: 'Ramipril 5mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '1.25 mg, 2.5 mg, 5 mg, 10 mg',
        description: `Ramipril is a medication classified as an angiotensin-converting enzyme (ACE) inhibitor. It is primarily prescribed for the treatment of hypertension (high blood pressure), heart failure, and to reduce the risk of heart attack and stroke in individuals with certain cardiovascular conditions.

        Ramipril works by inhibiting the action of the angiotensin-converting enzyme, which is responsible for the production of a hormone called angiotensin II. By blocking the effects of angiotensin II, Ramipril helps to relax and widen blood vessels, reduce fluid retention, and lower blood pressure.
        
        The dosage of Ramipril may vary depending on the individual's condition and response to treatment. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Ramipril is usually taken orally, with or without food, once or twice a day as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of blood pressure, kidney function, and electrolyte levels is important during treatment with Ramipril to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Ramipril may include dizziness, fatigue, cough, headache, and gastrointestinal disturbances such as nausea and diarrhea. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Ramipril, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Ramipril is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/indapamide 1.5mg sr.png'),
        dialogImage: require('../../img/products/cardiac/indapamide 1.5mg sr.png'),
        brandName: 'Indapamide 1.5mg SR Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '1.5 mg, 2.5 mg',
        description: `Indapamide Tablets belong to a class of medications known as diuretics or water pills. They are primarily prescribed for the treatment of high blood pressure (hypertension) and edema (fluid retention) associated with various conditions, such as heart failure and kidney disease.

        Indapamide works by increasing the excretion of excess salt and water from the body through the kidneys. This helps to reduce fluid accumulation, lower blood pressure, and relieve symptoms associated with fluid retention.
        
        The dosage of Indapamide Tablets may vary depending on the condition being treated and individual patient response. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Indapamide Tablets are usually taken orally, with or without food, once daily in the morning. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of blood pressure, kidney function, and electrolyte levels is essential during treatment with Indapamide to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Indapamide Tablets may include frequent urination, dizziness, low blood potassium levels (hypokalemia), muscle cramps, and gastrointestinal disturbances such as nausea and constipation. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Indapamide Tablets, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Indapamide Tablets are a prescription medication and should be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/isosorbide mononitrate 20mg.png'),
        dialogImage: require('../../img/products/cardiac/isosorbide mononitrate 20mg.png'),
        brandName: 'Isosorbide Mononitrate 20mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '10 mg, 20 mg, 25 mg, 30 mg, 40 mg, 50 mg, 60 mg',
        description: `Isosorbide Mononitrate is a medication classified as a nitrate. It is primarily prescribed for the prevention and treatment of angina pectoris, a condition characterized by chest pain or discomfort due to insufficient blood flow to the heart.

        Isosorbide Mononitrate works by relaxing and dilating the blood vessels, allowing more oxygen-rich blood to reach the heart. This helps to relieve chest pain and reduce the frequency and severity of angina episodes.
        
        The dosage of Isosorbide Mononitrate may vary depending on the individual's condition and response to treatment. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Isosorbide Mononitrate is usually taken orally, with or without food, and should be swallowed whole with a glass of water. It is typically taken two to three times a day, with an interval of at least seven hours between doses, to maintain a consistent level of the medication in the body.
        
        Regular monitoring of blood pressure and heart rate is important during treatment with Isosorbide Mononitrate to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Isosorbide Mononitrate may include headaches, dizziness, flushing, and low blood pressure. These side effects are usually temporary and diminish over time. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Isosorbide Mononitrate, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Isosorbide Mononitrate is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/Benidipine Hydrochloride 8mg.png'),
        dialogImage: require('../../img/products/cardiac/Benidipine Hydrochloride 8mg.png'),
        brandName: 'Benidipine 8mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '4 mg, 8 mg',
        description: `Benidipine is a medication classified as a calcium channel blocker. It is primarily prescribed for the treatment of hypertension (high blood pressure) and certain cardiovascular conditions, such as angina (chest pain) and coronary artery disease.

        Benidipine works by inhibiting the entry of calcium into smooth muscle cells of blood vessels, resulting in their relaxation and widening. This helps to lower blood pressure by reducing the resistance in the blood vessels and improving blood flow.
        
        The dosage of Benidipine may vary depending on the individual's condition and response to treatment. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Benidipine is usually taken orally, with or without food, as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of blood pressure is important during treatment with Benidipine to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Benidipine may include dizziness, flushing, headaches, peripheral edema (swelling in the extremities), and gastrointestinal disturbances such as nausea and constipation. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Benidipine, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Benidipine is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/dabigatran 110mg.jpg'),
        dialogImage: require('../../img/products/cardiac/dabigatran 110mg.jpg'),
        brandName: 'Dabigatran Etexilate 110mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '75 mg, 110 mg, 150 mg',
        description: `Dabigatran Etexilate is a medication classified as a direct oral anticoagulant. It is primarily prescribed for the prevention of blood clots and the reduction of the risk of stroke and systemic embolism in individuals with non-valvular atrial fibrillation.

        Dabigatran Etexilate works by inhibiting thrombin, a key enzyme involved in the clotting process. By blocking thrombin, Dabigatran Etexilate helps to prevent the formation of blood clots, thereby reducing the risk of stroke and other clot-related complications.
        
        The dosage of Dabigatran Etexilate may vary depending on the individual's condition, kidney function, and other factors. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Dabigatran Etexilate is usually taken orally, with or without food, as directed by a healthcare professional. The capsules should be swallowed whole with a glass of water and should not be chewed or opened.
        
        Regular monitoring of kidney function and blood tests, such as measurements of creatinine levels and complete blood counts, may be necessary during treatment with Dabigatran Etexilate to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Dabigatran Etexilate may include gastrointestinal disturbances such as indigestion, stomach pain, and nausea. In rare cases, serious bleeding events may occur. If any side effects become severe or persistent, or if any signs of bleeding (e.g., unusual bruising, prolonged bleeding) occur, it is important to seek immediate medical attention.
        
        Before starting Dabigatran Etexilate, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Dabigatran Etexilate is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/telmisartan 40mg + chlorothalidone 12.5mg + cilnidipine 10mg.png'),
        dialogImage: require('../../img/products/cardiac/telmisartan 40mg + chlorothalidone 12.5mg + cilnidipine 10mg.png'),
        brandName: 'Telmisartan 40 mg + Chlorthalidone 12.5 mg + Cilnidipine 10 mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '40 mg + 10 mg + 6.25 mg, 40 mg + 10 mg + 12.5 mg',
        description: `Telmisartan 40mg + Chlorothalidone 12.5mg + Cilnidipine 10mg is a combination medication that contains three active ingredients: Telmisartan, Chlorothalidone, and Cilnidipine. It is primarily prescribed for the treatment of hypertension (high blood pressure).

        Telmisartan belongs to a class of medications known as angiotensin II receptor blockers (ARBs). It works by blocking the action of a hormone called angiotensin II, which causes blood vessels to narrow. By blocking angiotensin II, Telmisartan helps to relax and widen blood vessels, resulting in lowered blood pressure.
        
        Chlorothalidone is a thiazide diuretic that works by increasing the excretion of excess salt and water from the body. This helps to reduce fluid accumulation and lower blood pressure.
        
        Cilnidipine is a calcium channel blocker that works by inhibiting the entry of calcium into smooth muscle cells of blood vessels, resulting in their relaxation and widening. This further helps to lower blood pressure by improving blood flow.
        
        The combination of Telmisartan, Chlorothalidone, and Cilnidipine allows for a synergistic effect, providing enhanced blood pressure control than each medication alone.
        
        The dosage of Telmisartan 40mg + Chlorothalidone 12.5mg + Cilnidipine 10mg may vary depending on the individual's condition and response to treatment. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Telmisartan 40mg + Chlorothalidone 12.5mg + Cilnidipine 10mg is usually taken orally, with or without food, as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of blood pressure and electrolyte levels is important during treatment with Telmisartan 40mg + Chlorothalidone 12.5mg + Cilnidipine 10mg to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Telmisartan 40mg + Chlorothalidone 12.5mg + Cilnidipine 10mg may include dizziness, fatigue, headaches, muscle cramps, increased urination, and gastrointestinal disturbances such as nausea and constipation. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Telmisartan 40mg + Chlorothalidone 12.5mg + Cilnidipine 10mg, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Telmisartan 40mg + Chlorothalidone 12.5mg + Cilnidipine 10mg is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/nebivolol + s-amlodipine.png'),
        dialogImage: require('../../img/products/cardiac/nebivolol + s-amlodipine.png'),
        brandName: 'Nebivolol 5 mg + S-Amlodipine 2.5 mg Tablets',
        manufacturer: 'Supreme Bioscience',
        strength: 'Nebivolol 5 mg + S-Amlodipine 2.5 mg',
        description: `Nebivolol + S-Amlodipine is a combination medication that contains two active ingredients: Nebivolol, a beta-blocker, and S-Amlodipine, a calcium channel blocker. It is primarily prescribed for the treatment of hypertension (high blood pressure).

        Nebivolol works by blocking the action of certain chemicals in the body, such as adrenaline, that can increase heart rate and blood pressure. This helps to relax blood vessels and reduce the workload on the heart, resulting in lowered blood pressure.
        
        S-Amlodipine works by inhibiting the entry of calcium into the smooth muscle cells of blood vessels, thereby promoting their relaxation and widening. This further helps to lower blood pressure by improving blood flow.
        
        The combination of Nebivolol and S-Amlodipine allows for a dual mechanism of action, providing more effective blood pressure control than each medication alone.
        
        The dosage of Nebivolol + S-Amlodipine may vary depending on the individual's condition and response to treatment. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Nebivolol + S-Amlodipine is usually taken orally, with or without food, as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of blood pressure is important during treatment with Nebivolol + S-Amlodipine to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Nebivolol + S-Amlodipine may include dizziness, fatigue, headaches, flushing, and gastrointestinal disturbances such as nausea and diarrhea. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Nebivolol + S-Amlodipine, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Nebivolol + S-Amlodipine is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/atorvastatin 10 + fenofibrate 106.jpg'),
        dialogImage: require('../../img/products/cardiac/atorvastatin 10 + fenofibrate 106.jpg'),
        brandName: 'Atorvastatin 10mg + Fenofibrate 160mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '5 mg + 145 mg, 10 mg + 145 mg, 20 mg + 145 mg, 10 mg + 160 mg, 20 mg + 160 mg',
        description: `Atorvastatin + Fenofibrate is a combination medication that contains two active ingredients: Atorvastatin and Fenofibrate. It is primarily prescribed for the treatment of dyslipidemia, a condition characterized by abnormal levels of cholesterol and triglycerides in the blood.

        Atorvastatin belongs to a class of medications known as statins. It works by inhibiting the enzyme HMG-CoA reductase, which plays a key role in cholesterol synthesis. By reducing the production of cholesterol in the liver, Atorvastatin helps to lower total cholesterol, LDL cholesterol (often referred to as "bad" cholesterol), and triglyceride levels, while increasing HDL cholesterol (often referred to as "good" cholesterol) levels.
        
        Fenofibrate is a fibrate medication that works by activating an enzyme called lipoprotein lipase. This enzyme helps to break down triglycerides, a type of fat found in the blood. By reducing triglyceride levels and increasing HDL cholesterol levels, Fenofibrate helps to improve the overall lipid profile.
        
        The combination of Atorvastatin and Fenofibrate allows for a synergistic effect, providing enhanced control over lipid levels compared to each medication alone.
        
        The dosage of Atorvastatin + Fenofibrate may vary depending on the individual's condition and response to treatment. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Atorvastatin + Fenofibrate is usually taken orally, with or without food, as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of lipid levels and liver function is important during treatment with Atorvastatin + Fenofibrate to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Atorvastatin + Fenofibrate may include muscle pain or weakness, gastrointestinal disturbances such as nausea and diarrhea, and abnormalities in liver function tests. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Atorvastatin + Fenofibrate, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Atorvastatin + Fenofibrate is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/cardiac/amlodipine 10.jpg'),
        dialogImage: require('../../img/products/cardiac/amlodipine 10.jpg'),
        brandName: 'Amlodipine 10 mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '2.5 mg, 5 mg, 10 mg',
        description: `Amlodipine is a medication classified as a calcium channel blocker. It is primarily prescribed for the treatment of hypertension (high blood pressure) and certain cardiovascular conditions, such as angina (chest pain) and coronary artery disease.

        Amlodipine works by inhibiting the entry of calcium into smooth muscle cells of blood vessels, resulting in their relaxation and widening. This helps to lower blood pressure by reducing the resistance in the blood vessels and improving blood flow.
        
        The dosage of Amlodipine may vary depending on the individual's condition and response to treatment. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Amlodipine is usually taken orally, with or without food, as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of blood pressure is important during treatment with Amlodipine to ensure its safe and effective use. It is important to attend all scheduled medical check-ups and follow-up appointments.
        
        Common side effects of Amlodipine may include dizziness, flushing, headaches, peripheral edema (swelling in the extremities), and gastrointestinal disturbances such as nausea and constipation. If any side effects become severe or persistent, it is recommended to consult a healthcare professional.
        
        Before starting Amlodipine, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Amlodipine is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    }
]

const diabeticCatlogue = [
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/dapagliflozin 5.png'),
        dialogImage: require('../../img/products/diabetic/dapagliflozin 5.png'),
        brandName: 'Dapagliflozin 5 mg Tablets',
        manufacturer: 'Supreme Bioscience',
        strength: '5 mg, 10 mg',
        description: `Dapagliflozin is a medication used to treat type 2 diabetes mellitus. It belongs to a class of medications known as sodium-glucose co-transporter 2 (SGLT2) inhibitors.

        Dapagliflozin works by inhibiting the SGLT2 protein in the kidneys, which normally reabsorbs glucose from the urine back into the bloodstream. By blocking this protein, dapagliflozin allows excess glucose to be eliminated through the urine, thereby lowering blood glucose levels.
        
        In addition to helping control blood sugar levels, dapagliflozin may also lead to modest weight loss and lower blood pressure. It is usually prescribed as part of a comprehensive treatment plan that includes a healthy diet, regular exercise, and other diabetes management strategies.
        
        Dapagliflozin is typically taken orally in the form of a tablet, usually once a day. The dosage may vary depending on the individual's condition and response to treatment, and it is important to follow the prescribed dosage and instructions provided by a healthcare professional.
        
        Common side effects of dapagliflozin may include urinary tract infections, increased urination, thirst, genital yeast infections, and mild dehydration. It is important to stay adequately hydrated while taking this medication.
        
        Before starting dapagliflozin, it is important to inform your healthcare provider about any existing medical conditions, ongoing treatments, allergies, and any other relevant information. They will determine if dapagliflozin is suitable for you and adjust the dosage accordingly.
        
        Dapagliflozin is a prescription medication and should only be used under the supervision of a qualified healthcare professional. Regular monitoring of blood sugar levels and kidney function is important during treatment.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/glipizide 5mg.png'),
        dialogImage: require('../../img/products/diabetic/glipizide 5mg.png'),
        brandName: 'Glipizide 5mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '5 mg, 7.5 mg, 10 mg',
        description: `Glipizide is a medication used to manage type 2 diabetes mellitus. It belongs to a class of medications known as sulfonylureas, which work by stimulating the release of insulin from the pancreas.

        Insulin is a hormone that helps regulate blood sugar levels. Glipizide helps lower blood sugar levels by increasing the release of insulin and promoting its effective use in the body.
        
        Glipizide is typically taken orally in the form of a tablet, usually once or twice daily, as prescribed by a healthcare professional. The dosage may vary depending on the individual's condition, response to treatment, and other factors. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Regular monitoring of blood sugar levels is important during treatment with glipizide to ensure its effectiveness and adjust the dosage if necessary. It is recommended to adhere to a balanced diet, exercise regularly, and make appropriate lifestyle changes to support the management of diabetes.
        
        Common side effects of glipizide may include gastrointestinal disturbances such as nausea, vomiting, and stomach discomfort. Hypoglycemia (low blood sugar) is also a possible side effect, which may cause symptoms such as dizziness, sweating, confusion, and shakiness. It is important to be aware of the signs of hypoglycemia and take appropriate measures, such as consuming glucose or a sugary snack, if low blood sugar occurs.
        
        Before starting glipizide, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Glipizide is a prescription medication and should only be used under the supervision of a qualified healthcare professional. Regular follow-up visits with the healthcare provider are important to monitor the response to treatment and make any necessary adjustments.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/gliclazide 40mg.png'),
        dialogImage: require('../../img/products/diabetic/gliclazide 40mg.png'),
        brandName: 'Gliclazide 40mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: 'Gliclazide 30mg, Gliclazide 40mg, Gliclazide 60mg, Gliclazide 80mg',
        description: `Gliclazide is a medication commonly used in the treatment of type 2 diabetes mellitus. It belongs to a class of medications called sulfonylureas, which work by stimulating the release of insulin from the pancreas.

        Insulin is a hormone that helps regulate blood sugar levels. Gliclazide helps lower blood sugar levels by increasing insulin production and promoting its effective use in the body.
        
        Gliclazide is usually taken orally in the form of a tablet, typically once or twice daily, as prescribed by a healthcare professional. The dosage may vary depending on the individual's condition, response to treatment, and other factors. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Regular monitoring of blood sugar levels is important during treatment with gliclazide to ensure its effectiveness and adjust the dosage if necessary. It is recommended to adhere to a balanced diet, exercise regularly, and make appropriate lifestyle changes to support the management of diabetes.
        
        Common side effects of gliclazide may include gastrointestinal disturbances such as nausea, vomiting, and stomach discomfort. Hypoglycemia (low blood sugar) is also a possible side effect, which may cause symptoms such as dizziness, sweating, confusion, and shakiness. It is important to be aware of the signs of hypoglycemia and take appropriate measures, such as consuming glucose or a sugary snack, if low blood sugar occurs.
        
        Before starting gliclazide, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Gliclazide is a prescription medication and should only be used under the supervision of a qualified healthcare professional. Regular follow-up visits with the healthcare provider are important to monitor the response to treatment and make any necessary adjustments.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/ticagrelor 90mg.jpg'),
        dialogImage: require('../../img/products/diabetic/ticagrelor 90mg.jpg'),
        brandName: 'Ticagrelor 90mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '60 mg, 90 mg',
        description: `Ticagrelor is a medication used for the prevention of cardiovascular events in patients with acute coronary syndrome (ACS) or a history of myocardial infarction (heart attack). It belongs to a class of drugs known as P2Y12 inhibitors, which work by inhibiting the activation of platelets in the blood.

        When a blood vessel is damaged, platelets in the blood form a clot to prevent bleeding. However, in certain cardiovascular conditions like ACS or after a heart attack, the formation of blood clots can be harmful and can further block blood flow to the heart, leading to serious complications. Ticagrelor helps prevent this by blocking the P2Y12 receptors on platelets, which inhibits their ability to stick together and form clots.
        
        Ticagrelor is typically prescribed in combination with aspirin, another antiplatelet medication. This dual antiplatelet therapy further reduces the risk of clot formation and subsequent cardiovascular events.
        
        One notable feature of ticagrelor is its reversible binding to the P2Y12 receptor, unlike some other antiplatelet medications which bind irreversibly. This reversibility allows for a faster recovery of platelet function once the drug is discontinued, which can be advantageous in case of bleeding complications or the need for urgent surgery.
        
        Common side effects of ticagrelor may include bleeding, shortness of breath, headache, dizziness, nausea, and gastrointestinal disturbances. It is important to inform healthcare providers about any other medications being taken, as some drugs may interact with ticagrelor and affect its effectiveness or increase the risk of bleeding.
        
        Ticagrelor is available in tablet form and is taken orally. The dosage and duration of treatment will be determined by a healthcare professional based on the individual's condition and medical history.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/glimepiride 1 + metformin 500 + pioglitazone 15.png'),
        dialogImage: require('../../img/products/diabetic/glimepiride 1 + metformin 500 + pioglitazone 15.png'),
        brandName: 'Glimepiride 1mg + Metformin 500mg + Pioglitazone 15mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: 'Glimepiride 1mg + Metformin 500mg + Pioglitazone 15mg, Glimepiride 2mg + Metformin 500mg + Pioglitazone 15mg',
        description: `Glimepiride, Metformin, and Pioglitazone are three medications combined into a single formulation for the management of type 2 diabetes mellitus. This combination medication provides the benefits of multiple drugs, working together to help control blood sugar levels effectively.

        Glimepiride belongs to a class of medications known as sulfonylureas. It stimulates the release of insulin from the pancreas, helping to lower blood sugar levels.
        
        Metformin is a medication classified as a biguanide. It works by reducing glucose production in the liver and improving the body's sensitivity to insulin, thereby lowering blood sugar levels.
        
        Pioglitazone is a thiazolidinedione medication that helps improve insulin sensitivity in the body's tissues, allowing better glucose uptake and utilization.
        
        The combination of Glimepiride, Metformin, and Pioglitazone addresses multiple aspects of glucose control, targeting insulin secretion, glucose production, and insulin sensitivity.
        
        The dosage of Glimepiride + Metformin + Pioglitazone may vary depending on the individual's condition, response to treatment, and other factors. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Glimepiride + Metformin + Pioglitazone is typically taken orally, usually with meals or as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of blood sugar levels and periodic medical check-ups are important during treatment with Glimepiride + Metformin + Pioglitazone to ensure its safe and effective use. It is important to adhere to the recommended diet, exercise, and lifestyle modifications advised by a healthcare professional.
        
        Common side effects of Glimepiride + Metformin + Pioglitazone may include gastrointestinal disturbances such as nausea, vomiting, diarrhea, and abdominal discomfort. Other possible side effects include weight gain, fluid retention, and an increased risk of hypoglycemia (low blood sugar). It is important to report any persistent or severe side effects to a healthcare professional.
        
        Before starting Glimepiride + Metformin + Pioglitazone, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Glimepiride + Metformin + Pioglitazone is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/metformin hydrochloride 1000mg.png'),
        dialogImage: require('../../img/products/diabetic/metformin hydrochloride 1000mg.png'),
        brandName: 'Metformin Hydrochloride 1000mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '250 mg, 500 mg, 850mg, 1000 mg',
        description: `Metformin hydrochloride is a medication commonly used for the management of type 2 diabetes mellitus. It belongs to the class of medications called biguanides, which help control blood sugar levels.

        Metformin hydrochloride works by reducing glucose production in the liver and improving the body's sensitivity to insulin. It does not stimulate the release of insulin from the pancreas but rather enhances the body's natural response to insulin.
        
        Metformin hydrochloride is usually taken orally in the form of a tablet, typically one to three times a day, as prescribed by a healthcare professional. The dosage may vary depending on the individual's condition, response to treatment, and other factors. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Regular monitoring of blood sugar levels is important during treatment with metformin hydrochloride to ensure its effectiveness and adjust the dosage if necessary. It is recommended to adhere to a balanced diet, exercise regularly, and make appropriate lifestyle changes to support the management of diabetes.
        
        Common side effects of metformin hydrochloride may include gastrointestinal symptoms such as nausea, vomiting, diarrhea, and abdominal discomfort. These side effects are usually mild and transient. In rare cases, metformin hydrochloride can cause a serious but rare side effect called lactic acidosis, characterized by symptoms such as muscle pain, difficulty breathing, and abdominal discomfort. It is important to seek medical attention if any unusual or severe side effects occur.
        
        Before starting metformin hydrochloride, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Metformin hydrochloride is a prescription medication and should only be used under the supervision of a qualified healthcare professional. Regular follow-up visits with the healthcare provider are important to monitor the response to treatment and make any necessary adjustments.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/glibenclamide 5 + metformin 500 + pioglitazone 15.png'),
        dialogImage: require('../../img/products/diabetic/glibenclamide 5 + metformin 500 + pioglitazone 15.png'),
        brandName: 'Glibenclamide 5mg + Metformin 500mg + Pioglitazone 15mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: 'Glibenclamide 5mg + Metformin 500mg + Pioglitazone 15mg, Glibenclamide 5mg + Metformin 500mg + Pioglitazone 7.5mg, ',
        description: `Glibenclamide, Metformin, and Pioglitazone are three medications combined into a single formulation for the management of type 2 diabetes mellitus. This combination medication provides the benefits of multiple drugs, working together to help control blood sugar levels effectively.

        Glibenclamide belongs to a class of medications known as sulfonylureas. It stimulates the release of insulin from the pancreas, helping to lower blood sugar levels.
        
        Metformin is a medication classified as a biguanide. It works by reducing glucose production in the liver and improving the body's sensitivity to insulin, thereby lowering blood sugar levels.
        
        Pioglitazone is a thiazolidinedione medication that helps improve insulin sensitivity in the body's tissues, allowing better glucose uptake and utilization.
        
        The combination of Glibenclamide, Metformin, and Pioglitazone addresses multiple aspects of glucose control, targeting insulin secretion, glucose production, and insulin sensitivity.
        
        The dosage of Glibenclamide + Metformin + Pioglitazone may vary depending on the individual's condition, response to treatment, and other factors. It is important to follow the prescribed dosage and treatment plan provided by a healthcare professional.
        
        Glibenclamide + Metformin + Pioglitazone is typically taken orally, usually with meals or as directed by a healthcare professional. The tablets should be swallowed whole with a glass of water.
        
        Regular monitoring of blood sugar levels and periodic medical check-ups are important during treatment with Glibenclamide + Metformin + Pioglitazone to ensure its safe and effective use. It is important to adhere to the recommended diet, exercise, and lifestyle modifications advised by a healthcare professional.
        
        Common side effects of Glibenclamide + Metformin + Pioglitazone may include gastrointestinal disturbances such as nausea, vomiting, diarrhea, and abdominal discomfort. Other possible side effects include weight gain, fluid retention, and an increased risk of hypoglycemia (low blood sugar). It is important to report any persistent or severe side effects to a healthcare professional.
        
        Before starting Glibenclamide + Metformin + Pioglitazone, it is important to provide a comprehensive medical history, ongoing treatments, allergies, and any other relevant information to the healthcare provider. This helps ensure the safe and effective use of the medication and minimize the risk of potential drug interactions or complications.
        
        Glibenclamide + Metformin + Pioglitazone is a prescription medication and should only be used under the supervision of a qualified healthcare professional.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/sitagliptin 50mg + metformin 500mg.jpg'),
        dialogImage: require('../../img/products/diabetic/sitagliptin 50mg + metformin 500mg.jpg'),
        brandName: 'Sitagliptin 50mg + Metformin 500mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '50mg + 500mg, 50mg + 1000mg, 100mg + 500mg, 100mg + 1000mg',
        description: `Sitagliptin and metformin are both medications used to treat type 2 diabetes. When used together as a combination therapy, they can provide more effective blood sugar control than either medication alone.

        Sitagliptin belongs to a class of drugs called dipeptidyl peptidase-4 (DPP-4) inhibitors. It works by increasing the levels of incretin hormones in the body. These hormones stimulate the release of insulin from the pancreas and reduce the production of glucose by the liver. By enhancing these natural mechanisms, sitagliptin helps to regulate blood sugar levels, especially after meals.
        
        Metformin is a member of the biguanide class of medications and is considered a first-line treatment for type 2 diabetes. It works by reducing the production of glucose by the liver and increasing the sensitivity of muscle cells to insulin, which allows for better glucose uptake. Metformin also helps to lower blood sugar levels by decreasing the absorption of glucose from the intestines.
        
        When sitagliptin and metformin are used in combination, they complement each other's mechanisms of action. Sitagliptin enhances the release of insulin and reduces glucagon secretion (a hormone that increases blood sugar levels), while metformin reduces the production of glucose by the liver and improves insulin sensitivity. This combination approach can help control blood sugar levels more effectively and improve glycemic control in patients with type 2 diabetes.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/acarbose 50.jpg'),
        dialogImage: require('../../img/products/diabetic/acarbose 50mg.jpg'),
        brandName: 'Acarbose 50mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '25 mg, 50 mg, 100 mg',
        description: `Acarbose is an oral medication used in the treatment of type 2 diabetes mellitus. It belongs to a class of drugs called alpha-glucosidase inhibitors. Acarbose works by slowing down the digestion and absorption of carbohydrates in the intestine. This helps to control blood sugar levels by preventing rapid spikes in blood glucose after a meal.

        When taken with meals, acarbose acts by inhibiting certain enzymes in the small intestine that are responsible for breaking down complex carbohydrates into simple sugars. By slowing down this process, acarbose delays the absorption of glucose from carbohydrates, thereby reducing the post-meal rise in blood sugar levels.
        
        Acarbose is typically prescribed as part of a comprehensive treatment plan for diabetes, which may include dietary changes, exercise, and other medications. It is commonly used in conjunction with other oral antidiabetic drugs such as metformin or sulfonylureas.
        
        It is important to note that acarbose is not effective in treating type 1 diabetes, as it specifically targets the digestion and absorption of carbohydrates. It is also not recommended for individuals with certain medical conditions, such as inflammatory bowel disease or intestinal blockage.
        
        Like any medication, acarbose can have side effects. Common side effects may include abdominal discomfort, bloating, flatulence, and diarrhea. These side effects are primarily due to the medication's mechanism of action in the intestine. In most cases, these symptoms are mild and temporary.
        
        If you have been prescribed acarbose or are considering its use, it is crucial to follow your healthcare provider's instructions and regularly monitor your blood sugar levels. Your doctor will determine the appropriate dosage and frequency of administration based on your individual needs and response to the medication.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/pioglitazone 30.jpg'),
        dialogImage: require('../../img/products/diabetic/pioglitazone 30mg.jpg'),
        brandName: 'Pioglitazone 30mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '7.5 mg, 15 mg, 30 mg',
        description: `Pioglitazone is an oral medication used in the treatment of type 2 diabetes mellitus. It belongs to a class of drugs known as thiazolidinediones or glitazones. Pioglitazone works by improving the body's sensitivity to insulin, a hormone that regulates blood sugar levels.

        In individuals with type 2 diabetes, there is a condition called insulin resistance, where the body's cells do not respond effectively to insulin. Pioglitazone helps to alleviate this insulin resistance by binding to specific receptors in fat, muscle, and liver tissues. This action increases the uptake and utilization of glucose (sugar) in these tissues, thereby reducing the amount of glucose in the bloodstream.
        
        Pioglitazone is typically prescribed as part of a comprehensive treatment plan for diabetes, which may include dietary changes, exercise, and other medications. It is often used in combination with other oral antidiabetic drugs, such as metformin, sulfonylureas, or insulin, to achieve better glycemic control.
        
        It is important to note that pioglitazone is not suitable for individuals with type 1 diabetes or diabetic ketoacidosis. It is primarily prescribed for type 2 diabetes and is not intended to be a substitute for lifestyle modifications, such as a healthy diet and regular exercise.
        
        Like any medication, pioglitazone can have side effects. Common side effects may include weight gain, edema (fluid retention), and mild anemia. In rare cases, it may also increase the risk of congestive heart failure or cause liver problems. Therefore, it is essential to regularly monitor liver function and cardiovascular health while taking pioglitazone.
        
        Pioglitazone may also increase the risk of bladder cancer, particularly when used for long periods or at high doses. Therefore, it is important for individuals taking pioglitazone to discuss this risk with their healthcare provider and be vigilant for any unusual symptoms, such as blood in the urine or urinary urgency.
        
        If you have been prescribed pioglitazone or are considering its use, it is crucial to follow your healthcare provider's instructions and regularly monitor your blood sugar levels. Your doctor will determine the appropriate dosage and frequency of administration based on your individual needs and response to the medication.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/repaglinide 2.jpg'),
        dialogImage: require('../../img/products/diabetic/repaglinide 2 -1.jpg'),
        brandName: 'Repaglinide 2mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: '0.5 mg, 1 mg, 2 mg',
        description: `Repaglinide is an oral medication used in the treatment of type 2 diabetes mellitus. It belongs to a class of drugs called meglitinides or glinides. Repaglinide helps to lower blood sugar levels by stimulating the release of insulin from the pancreas.

        In individuals with type 2 diabetes, there is a condition called impaired insulin secretion, where the pancreas does not produce enough insulin or the body's cells do not respond effectively to insulin. Repaglinide works by binding to specific receptors on the beta cells of the pancreas, which stimulates the release of insulin into the bloodstream. This increased insulin release helps to regulate blood sugar levels after a meal.
        
        Repaglinide is typically taken before each meal, usually within 30 minutes before eating, to match the insulin response with the rise in blood sugar that occurs after meals. It is important to follow a meal plan and timing recommended by your healthcare provider to ensure optimal control of blood sugar levels.
        
        This medication is often prescribed as part of a comprehensive treatment plan for diabetes, which may also include dietary changes, exercise, and other antidiabetic medications. It can be used alone or in combination with other oral antidiabetic drugs such as metformin.
        
        It is important to note that repaglinide is not suitable for individuals with type 1 diabetes or diabetic ketoacidosis. It is primarily prescribed for type 2 diabetes and is not intended to be a substitute for lifestyle modifications, such as a healthy diet and regular exercise.
        
        Like any medication, repaglinide can have side effects. Common side effects may include hypoglycemia (low blood sugar), weight gain, and gastrointestinal symptoms such as nausea, diarrhea, or abdominal pain. Hypoglycemia is the most significant risk associated with repaglinide, so it is important to monitor blood sugar levels closely and be aware of the signs and symptoms of low blood sugar.
        
        If you have been prescribed repaglinide or are considering its use, it is crucial to follow your healthcare provider's instructions and regularly monitor your blood sugar levels. Your doctor will determine the appropriate dosage and frequency of administration based on your individual needs and response to the medication.`
    },
    {
        imageFit: 'cover',
        displayImage: require('../../img/products/diabetic/telmi 20 + meto 500.jpg'),
        dialogImage: require('../../img/products/diabetic/telmi 20 + meto 500 -1.jpg'),
        brandName: 'Telmisartan 20mg + Metformin 1000mg Tablets',
        manufacturer: 'Skyogen Healthcare Ltd',
        strength: 'Telmisartan 20mg + Metformin 1000mg',
        description: `Telmisartan is available as telmisartan alone and is used for the treatment of hypertension. It belongs to the class of medications known as angiotensin II receptor blockers (ARBs) and works by blocking the action of angiotensin II, a hormone that constricts blood vessels, thus lowering blood pressure.

        Metformin, on the other hand, is an oral antidiabetic medication used to manage type 2 diabetes. It helps control blood sugar levels by improving insulin sensitivity and reducing glucose production in the liver. Metformin is often prescribed as a first-line treatment for type 2 diabetes, particularly for individuals who are overweight or obese.
        
        While telmisartan and metformin are commonly prescribed together for patients with both hypertension and type 2 diabetes, they are generally taken as separate medications, each with their specific dosages and administration instructions. The combination of telmisartan and metformin is intended to address both blood pressure control and glycemic management in individuals with both conditions.
        
        It's important to note that the use of any medications, including telmisartan and metformin, should be determined by a healthcare professional who can evaluate your specific medical history and provide personalized recommendations.`
    },
]

const hepatitisCatlogue = [
    {
        displayImage: require('../../img/products/hepatitis/Dacihep Tablet.jpg'),
        dialogImage: require('../../img/products/hepatitis/Dacihep Tablet 2.jpg'),
        brandName: 'Dacihep 60mg Tablets',
        composition: 'Daclatasvir 60mg',
        manufacturer: 'Zydus Cadila',
        strength: '30 mg, 60 mg',
        description: `Dacihep 60mg Tablet is an antiviral medication. It is used along with other medications for the treatment of chronic hepatitis C virus (HCV) infection.

        Dacihep 60mg Tablet should be taken in the dose and duration as advised by your doctor. It should be taken with or without food, preferably at a fixed time. Do not skip any doses and finish the full course of treatment even if you feel better If you miss a dose, take it as soon as possible. However, if it is almost time for your next dose, skip the missed dose and go back to your regular schedule. Do not double the dose.
        
        It has fewer side effects as compared to other similar medicines, like fatigue, headache, nausea, insomnia (difficulty sleeping), and anemia (low red blood cell count). Inform your doctor if these side effects persist for a longer duration. Please consult your doctor if you are pregnant, planning to conceive, or breastfeeding.`
    },
    {
        displayImage: require('../../img/products/hepatitis/Ledifos Tablet.jpg'),
        dialogImage: require('../../img/products/hepatitis/Ledifos Tablet 2.jpg'),
        brandName: 'Ledifos Tablets',
        composition: 'Ledipasvir 90mg + Sofosbuvir 400mg',
        manufacturer: 'Hetero Drugs Ltd',
        strength: '90mg + 400mg',
        description: `Ledifos Tablet is a combination of two antiviral medicines. This prescription medicine is used in the treatment of chronic hepatitis C virus (HCV) infection. It fights against the viruses to resolve the infection.

        Ledifos Tablet should be taken in the prescribed dose and duration. It can be taken with or without food, but take it at the same time daily. It is advised not to consume more than the recommended dose. It is important to inform your doctor if you have any health conditions such as liver or kidney disease. It is harmful to consume alcohol along with this medicine, so it is advised to limit or avoid alcohol. The course of the medicine should be completed for better results.
        
        The common side effects of this medicine are tiredness and headache. You should drink plenty of fluid and eat a healthy diet to prevent or overcome the side effects. Before taking the medicine, inform your doctor if you are taking any other medicines or supplements.`
    },
    {
        displayImage: require('../../img/products/hepatitis/Sofovir 400mg.jpg'),
        dialogImage: require('../../img/products/hepatitis/Sofovir 400mg 2.jpg'),
        brandName: 'Sofovir 400mg Tablets',
        composition: 'Sofosbuvir 400 mg',
        manufacturer: 'Hetero Healthcare Ltd',
        strength: '400 mg',
        description: `SOFOVIR 400MG TABLET contains Sofosbuvir which belongs to the group of medicines called Antiviral agents. SOFOVIR 400MG TABLET is used with Ribavirin/ Peginterferon alfa and ribavirin (antiviral medicines) to treat hepatitis C viral infection in adults and children (aged 3 years/above), which is a type of viral infection that infects the liver.

        SOFOVIR 400MG TABLET is not recommended for use in patients allergic to Sofosbuvir.
        
        Before taking SOFOVIR 400MG TABLET, inform your doctor if you have a  history of hepatitis B virus infection, and/or suffer from diabetes, heart problems, kidney problems, and/or other liver problems.
        
        SOFOVIR 400MG TABLET is not recommended for use during pregnancy and in women planning for pregnancy since Ribavirin (antiviral medicine used with Sofosbuvir) may cause harm to the unborn baby. Women of childbearing potential should use effective contraceptive measures to avoid getting pregnant and undergo a routine pregnancy test during and after treatment (for 9 months).  If accidental pregnancy occurs, then it should be informed to the doctor immediately. The medicine is also not recommended for use in breastfeeding women since it is not known whether the medicine may pass through breast milk.
        
        SOFOVIR 400MG TABLET is not recommended for use in children (aged below 3 years) and is advised to be used with caution in elderly patients (aged 65 years/above) after consulting the doctor.  
        
        The most common side effects of taking SOFOVIR 400MG TABLET are fever, chills, diarrhoea, nausea, vomiting, dizziness, feeling depressed/anxious/agitated, and/or headache. Consult with your doctor if any of these symptoms worsen.`
    },
    {
        displayImage: require('../../img/products/hepatitis/Harmoni F Tablet.jpg'),
        dialogImage: require('../../img/products/hepatitis/Harmoni F Tablet.jpg'),
        brandName: 'Harmoni-F Tablet',
        composition: 'Cyproterone 2mg + Ethinyl Estradiol 0.035mg + Folic Acid 5mg',
        manufacturer: 'Alembic Pharmaceuticals Ltd',
        strength: '2mg + 0.035mg + 5mg',
        description: `Harmoni-F Tablet is a combination medicine used in the treatment of polycystic ovarian syndrome (PCOS), as it helps to regularize the menstrual cycle. It also treats severe acne and hirsutism (excess hair growth, mainly on face and chest) in women.

        Harmoni-F Tablet can be taken with or without food, but take it at the same time to get the most benefit. It should be taken as per your doctor's advice. The dose and how often you take it depends on what you are taking it for. Your doctor will decide how much you need to improve your symptoms. Swallow the tablets whole with a glass of water. You should take this medicine for as long as it is prescribed for you. It also works as a contraceptive, so better to avoid taking any other hormonal pills while taking this medicine.
        
        The most common side effects of this medicine include nausea, stomach pain, headache, breast pain, and mood changes. If these bother you or appear serious, let your doctor know. There may be ways of reducing or preventing them. Usually, missing a dose may cause spotting or brown discharge in the middle of the cycle. Let your doctor know if this occurs frequently. Consult with your doctor immediately if you notice unexplained swelling and pain in your limbs, shortness of breath, chest pain or changes in vision. Do not continue with the medicine and consult with the doctor if your periods do not start within a week of the treatment.
        
        Before taking this medicine, let your doctor know if you smoke and are over 35, or if you have ever had a heart attack or have cancer of uterus/cervix, or vagina. Your doctor should also know about all other medicines you are taking as many of these may make this medicine less effective or change the way it works. Avoid the use of medicine if you are pregnant or breastfeeding.`
    }
]