import './Home.css';
import { Fragment, useState } from 'react';
import { Button, IconButton, Modal } from '@mui/material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from 'react-helmet';
// Screen Animation
import { Fade, Rotate, Zoom } from 'react-reveal';

// Icons
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 0 },
        items: 1,
    }
};

function Home() {
    const [selectedImgIndex, setSelectedImgIndex] = useState(0)
    const [introModalOpen, setIntroModalOpen] = useState(true);

    return (
        <div id='home'>
            <Helmet>
                <title>Narix Pharmaceuticals Private Limited</title>
            </Helmet>

            <Carousel
                swipeable={false}
                draggable={false}
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                containerClass="home_carouselCont"
                itemClass="home_carouselImgCont"
            >
                {imgArr?.map((img, i) => (
                    <Fragment key={i}>
                        <div className='home_carouselImgCont_backdrop' />
                        <img src={img.img} />
                        <h3>{img.slogan}</h3>
                    </Fragment>
                ))}
            </Carousel>

            <section className='home_specialisation'>
                <Fade left>
                    <h2>Specialisation</h2>
                    <div className='home_specialisation_borderBottom' />
                </Fade>

                <Fade bottom>
                    <p className='home_specialisation_text' >
                        Specialized in providing comprehensive import & export services for pharmaceutical drugs, specifically tailored to meet the needs of patients and clinical trials. With our extensive expertise in the field, we understand the complexities involved in exporting pharmaceutical products, including compliance with regulatory requirements and adherence to international quality standards. Our strong relationships with international suppliers, logistics providers, and regulatory bodies allow us to streamline the export process, ensuring efficient and timely delivery of pharmaceutical products.
                    </p>
                </Fade>

                <div className='home_specialisation_iconCont' >
                    {imageSpecialisationArray?.map((img, i) => (
                        <Zoom key={i} >
                            <div className={`home_specialisation_iconData ${selectedImgIndex === i ? 'home_specialisation_iconData_active' : ''}`}
                                onClick={() => setSelectedImgIndex(i)}
                            >
                                <div className='home_specialisation_icon' style={{ background: `url(${img.img}) no-repeat center/contain` }} />
                                <p>{img.heading}</p>
                            </div>
                        </Zoom>
                    ))}
                </div>

                <div className='home_specialisation_iconData_read' >
                    <img src={imageSpecialisationArray[selectedImgIndex].dataImg} alt={imageSpecialisationArray[selectedImgIndex].heading} />

                    <div className='home_specialisation_iconData_readData' >
                        <h1>{imageSpecialisationArray[selectedImgIndex].heading}</h1>
                        <h4>{imageSpecialisationArray[selectedImgIndex].subHeading}</h4>

                        <div className='home_specialisation_iconData_read_companyName_cont' >
                            {imageSpecialisationArray[selectedImgIndex].companiesArr?.map((c, i) => (
                                <h2 className='home_specialisation_iconData_read_companyName' key={i} >
                                    <DoneAllIcon /> {c}
                                </h2>
                            ))}
                        </div>
                    </div>
                </div>
            </section>


            <section className='home_services'>
                <div className='home_services_cont' >
                    <Fade bottom>
                        <h2>Exceptional Service, Exceptional Care</h2>
                        <div className='home_services_borderBottom' />

                        <div className='home_services_sec1' >
                            <div>
                                <p>01.</p>
                                <h4>Guaranteed Timely Delivery upon Order Confirmation for All Institutions</h4>
                            </div>
                            <div>
                                <p>02.</p>
                                <h4>Prompt and Punctual Delivery Service Bringing Healthcare to Your Doorstep</h4>
                            </div>
                            <div>
                                <p>03.</p>
                                <h4>Meticulous Temperature Surveillance and Certification of Thermo Labile Products, Tailored to Consumer Requirements</h4>
                            </div>
                        </div>

                        <div className='home_services_sec2'>
                            <div>
                                <p>04.</p>
                                <h4>Immersive IP-Based CCCTV System for Live, Real-Time Stock Monitoring and Visualization</h4>
                            </div>
                            <div>
                                <p>05.</p>
                                <h4>Prepare for an Enigmatic Experience: Unraveling the Veil with Upcoming Software and Website Overhaul, Unleashing Real-Time Sales and Inventory Reports!</h4>
                            </div>
                            <div>
                                <p>06.</p>
                                <h4>Real Time Viewing Of Controlled Temperature Data Generated Via Data Loggers</h4>
                            </div>
                        </div>

                        <div className='home_services_sec3'>
                            <div>
                                <p>07.</p>
                                <h4>Real Time Delivery Tracking Of Every Supplies</h4>
                            </div>
                            <div>
                                <p>08.</p>
                                <h4>Real Time Completion Of Delivery With Map Or Real Time Acknowledgement</h4>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>

            <section className='home_marketCoverage'>
                <div>
                    <Rotate top left>
                        <h5>Market Coverage</h5>
                        <p>
                            With our expansive global reach spanning across approximately 180 countries, we proudly maintain dedicated warehousing facilities designed specifically for the storage of pharmaceutical products. These facilities adhere to Good Storage and Distribution Practices (GSDP), and undergo regular inspections and monitoring to ensure compliance with stringent quality standards.
                        </p>
                        <p>
                            Relationship With More Than 600 Medical, Surgical, Radiation And Other Specialised Kol’s
                        </p>
                        <p>
                            Through our extensive network and dedication to excellence, we ensure the safe and efficient handling of pharmaceutical products, while collaborating with top industry professionals to continuously enhance our services and knowledge base.
                        </p>
                    </Rotate>

                    <Zoom>
                        <Button variant='contained' disableElevation
                            onClick={() => window.open('https://wa.me/919082399514?text=Enquiry%20for%20Pharmaceuticals%20Products', '_blank')}
                        >
                            Get In Touch With Us
                        </Button>
                    </Zoom>
                </div>

                <Rotate top right>
                    <img src={require('../../img/market-coverage.jpg')} />
                </Rotate>
            </section>

            <Modal open={introModalOpen} className='homeIntro_modal' >
                <div className='homeIntro_modalCont' >
                    <IconButton size='small' onClick={() => setIntroModalOpen(false)} >
                        <ArrowBackIosRoundedIcon />
                    </IconButton>
                    <video
                        src={require('../../img/intro.mp4')}
                        controlsList="nodownload"
                        autoPlay={introModalOpen}
                        type="video/mp4"
                        controls
                    />
                </div>
            </Modal>
        </div>
    )
}

export default Home

const imgArr = [
    {
        img: require('../../img/carousel/carousel-1.jpg'),
        slogan: "Delivering Health Worldwide: Our Pharmaceuticals, Your Wellness",
        details: "Our mission is to bring health and wellness to people across the globe through our high-quality pharmaceutical products. We strive to deliver effective medicines that improve lives and promote well-being, ensuring that your wellness is our top priority.",
    },
    {
        img: require('../../img/carousel/carousel-2.jpg'),
        slogan: "Quality Pharmaceuticals, Global Reach: Enhancing Healthcare Access Across Borders",
        details: "We take pride in providing top-notch pharmaceutical products with a global reach. Our commitment to quality means that people around the world can access safe and effective medicines, improving healthcare access and positively impacting communities across borders.",
    },
    {
        img: require('../../img/carousel/carousel-3.jpg'),
        slogan: "Empowering Health Beyond Borders: Bridging the Gap with Our Pharma Exports",
        details: "We believe that good health knows no boundaries. By exporting our pharmaceuticals, we empower individuals and communities by bridging the healthcare gap. Our products reach far and wide, making a significant difference in the lives of people around the world.",
    },
    {
        img: require('../../img/carousel/carousel-4.jpg'),
        slogan: "Innovating Medicines, Transforming Lives: Enriching Global Health through Pharma Exports",
        details: "Our dedication to innovation drives us to develop groundbreaking medicines that have the power to transform lives. Through our pharma exports, we contribute to enriching global health by providing cutting-edge solutions that address critical healthcare needs and make a positive impact worldwide.",
    },
    {
        img: require('../../img/carousel/carousel-5.jpg'),
        slogan: "Your Health, Our Priority, Anywhere: Bringing Wellness to Every Corner of the Globe",
        details: "Wherever you are in the world, your health is our utmost priority. Our commitment to bringing wellness to every corner of the globe drives us to provide accessible healthcare solutions. We strive to make a difference by ensuring that quality pharmaceuticals are available to improve health outcomes globally.",
    }
]

const imageSpecialisationArray = [
    {
        img: require('../../img/specialisation/ONCOLOGY.png'),
        heading: 'ONCOLOGY',
        dataImg: require('../../img/specialisation/ONCOLOGY-DATA.jpg'),
        subHeading: 'Oncology is a branch of medicine that deals with the prevention, diagnosis, and treatment of cancer. Principals We Work With:',
        companiesArr: ['BAYER', 'NOVARTIS', 'ZYDUS CADILA', 'RELIANCE LIFESCIENCE', 'ABBOTT HEALTHCARE', 'MYLAN PHARMACEUTICALS',
            'ASTRA ZENECA', 'MERCK', 'EISAI PHARMA', 'ELI LILLY', 'ASTELLAS PHARMA', 'BOEHRINGER INGELHEIM', 'PLASMAGEN LIFE SCIENCES',
            'BOSTON SCIENTIFIC', 'BDR PHARMACEUTICALS', 'PANACEA BIOTEC',
        ]
    },
    {
        img: require('../../img/specialisation/HEMATOLOGY.png'),
        heading: 'HEMATOLOGY',
        dataImg: require('../../img/specialisation/HEMATOLOGY-DATA.jpg'),
        subHeading: 'Hematology, is the branch of medicine concerned with the study of the cause, prognosis, treatment, and prevention of diseases related to blood. Principals We Work With :',
        companiesArr: [
            "RELIANCE LIFESCIENCE",
            "NOVARTIS",
            "ABBOTT HEALTHCARE",
            "BAYER",
            "ZYDUS CADILA",
            "MYLAN PHARMACEUTICALS",
            "ASTRA ZENECA",
            "ASTELLAS PHARMA",
            "MERCK",
            "EISAI PHARMA",
            "ELI LILLY",
        ]
    },
    {
        img: require('../../img/specialisation/DERMATOLOGY.png'),
        heading: 'DERMATOLOGY',
        dataImg: require('../../img/specialisation/DERMATOLOGY-DATA.jpg'),
        subHeading: 'Dermatology is the branch of medicine dealing with the skin. It is a speciality with both medical and surgical aspects. A dermatologist is a specialist doctor who manages diseases related to skin, hair and nails and some cosmetic problems.',
        companiesArr: [
            "NOVARTIS",
            "ASTRA ZENECA",
            "RELIANCE LIFESCIENCE",
            "ABBOTT HEALTHCARE",
            "ZYDUS CADILA",
            "MYLAN PHARMACEUTICALS",
            "ELI LILLY",
            "ASTELLAS PHARMA",
            "BAYER",
            "EISAI PHARMA",
            "MERCK",
        ]
    },
    {
        img: require('../../img/specialisation/OSTEOLOGY.png'),
        heading: 'OSTEOLOGY',
        dataImg: require('../../img/specialisation/OSTEOLOGY-DATA.jpg'),
        subHeading: 'A subdiscipline of anatomy, anthropology, and paleontology, osteology is a detailed study of the structure of bones, skeletal elements, teeth, microbone morphology, function, disease, pathology, the process of ossification (from cartilaginous molds), and the resistance and hardness of bones (biophysics).',
        companiesArr: [
            "MYLAN PHARMACEUTICALS", "RELIANCE LIFESCIENCE", "BAYER", "NOVARTIS", "ZYDUS CADILA", "ABBOTT HEALTHCARE",
            "ASTRA ZENECA", "MERCK", "EISAI PHARMA", "ELI LILLY",
            "ASTELLAS PHARMA", "BOEHRINGER INGELHEIM", "PLASMAGEN LIFE SCIENCES", "BOSTON SCIENTIFIC",
            "BDR PHARMACEUTICALS", "PANACEA BIOTEC"
        ]
    },
    {
        img: require('../../img/specialisation/CRITICALCARE.png'),
        heading: 'CRITICAL CARE',
        dataImg: require('../../img/specialisation/CRITICALCARE-DATA.jpg'),
        subHeading: 'Intensive care medicine, also called critical care medicine, is a medical specialty that deals with critically ill patients who have, are at risk of, or are recovering from conditions that may be life-threatening. It includes providing life support, invasive monitoring techniques, resuscitation, and end-of-life care. Principals We Work With :',
        companiesArr: ['LIFECARE INNOVATIONS', 'MYLAN PHARMACEUTICALS', 'ABBOTT HEALTHCARE', 'INDIA BULLS PHARMACEUTICALS']
    },
    {
        img: require('../../img/specialisation/NEPHROLOGY.png'),
        heading: 'NEPHROLOGY',
        dataImg: require('../../img/specialisation/NEPHROLOGY-DATA.jpg'),
        subHeading: 'Nephrology is a specialty of medicine focused on the kidneys, specifically normal kidney function and kidney disease, the preservation of kidney health, and the treatment of kidney disease, from diet and medication to renal replacement therapy.',
        companiesArr: [
            "MERCK",
            "NOVARTIS",
            "BAYER",
            "RELIANCE LIFESCIENCE",
            "ABBOTT HEALTHCARE",
            "ZYDUS CADILA",
            "MYLAN PHARMACEUTICALS",
            "ELI LILLY",
            "ASTRA ZENECA",
            "EISAI PHARMA",
            "ASTELLAS PHARMA"
        ]
    },
    {
        img: require('../../img/specialisation/OPHTHALMOLOGY.png'),
        heading: 'OPHTHALMOLOGY',
        dataImg: require('../../img/specialisation/OPHTHALMOLOGY-DATA.jpg'),
        subHeading: 'Ophthalmology is a branch of medicine and surgery which deals with the diagnosis and treatment of eye disorders.',
        companiesArr: ['LIFECARE INNOVATIONS', 'MYLAN PHARMACEUTICALS', 'ABBOTT HEALTHCARE', 'INDIA BULLS PHARMACEUTICALS']
    },
    {
        img: require('../../img/specialisation/GASTROLOGY.png'),
        heading: 'GASTROLOGY',
        dataImg: require('../../img/specialisation/GASTROLOGY-DATA.jpg'),
        subHeading: 'Gastroenterology is the branch of medicine focused on the digestive system and its disorders. Diseases affecting the gastrointestinal tract, which include the organs from mouth into anus, along the alimentary canal, are the focus of this speciality. Physicians practicing in this field are called gastroenterologists.',
        companiesArr: ['RELIANCE LIFESCIENCE', 'APRAZER HEALTHCARE', 'NATCO PHARMA']
    }
]