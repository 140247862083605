export const menuItems = [
    { name: 'Home', link: '/' },
    { name: 'Our Products', dropIcon: true },
    { name: 'About Us', link: '/about-us' },
    { name: 'Contact', link: '/contact' },
]

export const productsCategory = [
    {
        name: 'Erectile Dysfunction',
        link: '/products/ed'
    },
    {
        name: 'Cancer',
        link: '/products/cancer'
    },
    {
        name: 'Antidiabetic',
        link: '/products/antidiabetic'
    },
    // {
    //     name: 'Gastro',
    //     link: '/products/gastro'
    // },
    {
        name: 'Hepatitis',
        link: '/products/hepatitis'
    },
    {
        name: 'Cardiac',
        link: '/products/cardiac'
    },
]